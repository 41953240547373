<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Documentos</div>
            </v-col>
        </v-row>

        <br />

        <div class="mt-4" v-if="dataset.loading">
            <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </div>

        <div v-html="dataset.description"></div>

        <v-row v-if="!this.dataset.docs">
            <v-col>
                <span class="page-subtitle">Nenhum documento encontrado</span>
            </v-col>
        </v-row>

        <v-expansion-panels>
            <template v-for="(group) in Object.keys(groupedDocs)">
                <v-expansion-panel :key="`panel-${group}`" class="custom-panel">
                    <v-expansion-panel-header class="header-text">
                        {{ getDocumentTypeName(group) }}
                        <template v-slot:actions>
                            <v-icon color="white">
                                $expand
                            </v-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-list class="mb-n2 mt-3">
                            <template v-for="(item, index) in groupedDocs[group]">
                                <a :key="`doc-${group}-${index}`" :href="item.doc_url"
                                    class="pa-0 d-flex flex-column align-start list-item-link mb-2" target="_blank">
                                    <v-col class="pa-0">
                                        <v-list-item class="d-flex align-center">
                                            <v-list-item-avatar>
                                                <v-img :src="getClientLogo(item.client_id)"></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content
                                                class="d-flex justify-space-between align-center flex-grow-1">
                                                <v-row
                                                    class="d-flex align-center text-uppercase font-weight-medium mediumGray--text">
                                                    <v-col cols="12" sm="5">
                                                        <span>{{ item.name }}</span>
                                                    </v-col>
                                                    <!-- <v-col cols="12" sm="3">
                                                        <span class="ml-6"
                                                            v-if="item.protocoled_at && item.protocoled_at !== '0000-00-00 00:00:00'">
                                                            {{
                                                                item.protocoled_at | defaultDate("DD/MM/YYYY") }}</span>
                                                        <span class="ml-6" v-else>N/A</span>
                                                    </v-col> -->
                                                </v-row>
                                            </v-list-item-content>
                                            <v-icon>mdi-download</v-icon>
                                        </v-list-item>
                                        <v-divider v-if="index < groupedDocs[group].length - 1"
                                            :key="`divider-${group}-${index}`" class="primary"></v-divider>
                                    </v-col>
                                </a>
                            </template>
                        </v-list>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </template>
        </v-expansion-panels>
    </v-container>
</template>

<script>
import 'moment/locale/pt-br'
import clientDocTypes from "@/common/data/client-doc-types.data"
import axios from "axios"

export default {
    data: () => ({
        user: null,
        dataset: {
            docs: [],
            description: '',
            loading: false,
        },
        clientDocTypes: clientDocTypes,
    }),

    computed: {
        groupedDocs() {
            return this.dataset.docs.reduce((groups, doc) => {
                if (!groups[doc.type]) {
                    groups[doc.type] = [];
                }
                groups[doc.type].push(doc);
                return groups;
            }, {});
        },

        lastTypeIndex() {
            const types = Object.keys(this.groupedDocs);
            return types.length - 1;
        },

    },

    mounted: function () {
    },

    created() {
        this.load();
        this.loadText();

        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'about'
        })
    },

    methods: {

        getDocumentTypeName(type) {
            const documentType = this.clientDocTypes.find(docType => docType.id == type);
            // return documentType ? documentType.name : 'Documento Desconhecido';
            return "Principais Documentos";
        },

        getClientLogo(clientId) {

            return require("@/assets/logo-123milhas.png");
        },

        async load() {

            this.dataset.loading = true;

            try {

                const result = await axios.get(`/api/client-docs/list-docs`);

                this.dataset.docs = result.data.content.data.filter(doc => doc.type !== 2 && doc.type !== 3);

                // this.dataset.docs = [
                //     {
                //         "id": 2,
                //         "name": "01. INICIAL DA RECUPERAÇÃO JUDICIAL",
                //         "order": 1,
                //         "file": "document-bf2eef89-41dd-4b0f-8736-7e5dbe23e66f",
                //         "created_at": "2024-05-07 15:10:58",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-bf2eef89-41dd-4b0f-8736-7e5dbe23e66f"
                //     },
                //     {
                //         "id": 4,
                //         "name": "01.1 RELAÇÃO DE CREDORES RECUPERANDAS E COMPROVANTE CUSTAS",
                //         "order": 2,
                //         "file": "document-bf094744-8f28-4f56-82be-2e088b6364cc",
                //         "created_at": "2024-05-07 15:11:59",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-bf094744-8f28-4f56-82be-2e088b6364cc"
                //     },
                //     {
                //         "id": 6,
                //         "name": "01.2 RELAÇÃO DE CREDORES RECUPERANDA NOVUM",
                //         "order": 3,
                //         "file": "document-e57bb0ac-0497-42e6-8cab-f000759b1081",
                //         "created_at": "2024-05-07 15:20:32",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e57bb0ac-0497-42e6-8cab-f000759b1081"
                //     },
                //     {
                //         "id": 8,
                //         "name": "01.4 CERTIDÕES",
                //         "order": 4,
                //         "file": "document-1b8a0411-94a7-4775-ab2b-03bfda2b4363",
                //         "created_at": "2024-05-07 15:22:39",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1b8a0411-94a7-4775-ab2b-03bfda2b4363"
                //     },
                //     {
                //         "id": 9,
                //         "name": "02. DECISÃO DEFERIMENTO DO PROCESSAMENTO",
                //         "order": 5,
                //         "file": "document-c0937f7b-1f5b-4e22-9bad-ae4f6738a5b6",
                //         "created_at": "2024-05-07 15:24:38",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-c0937f7b-1f5b-4e22-9bad-ae4f6738a5b6"
                //     },
                //     {
                //         "id": 10,
                //         "name": "03. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 6,
                //         "file": "document-58eb7b1b-3002-44de-872c-bbe966373d40",
                //         "created_at": "2024-05-07 15:24:58",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-58eb7b1b-3002-44de-872c-bbe966373d40"
                //     },
                //     {
                //         "id": 11,
                //         "name": "04. CERTIDÃO EDITAL DO ART. 52 §1º DA LEI 11.101",
                //         "order": 7,
                //         "file": "document-4a711dd5-ed12-4bff-9cc6-bb19b7f1a8f4",
                //         "created_at": "2024-05-07 15:25:27",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4a711dd5-ed12-4bff-9cc6-bb19b7f1a8f4"
                //     },
                //     {
                //         "id": 12,
                //         "name": "04.1 EDITAL DO ART. 52 §1º INCISO I DA LEI 11.101",
                //         "order": 8,
                //         "file": "document-eeb62145-f9bc-4aed-9f3c-9654239cf1ff",
                //         "created_at": "2024-05-07 15:25:53",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-eeb62145-f9bc-4aed-9f3c-9654239cf1ff"
                //     },
                //     {
                //         "id": 13,
                //         "name": "05. TERMO DE COMPROMISSO ADMINISTRADORES JUDICIAIS",
                //         "order": 9,
                //         "file": "document-27b60958-e576-4803-8cd0-dcfcd3ebf978",
                //         "created_at": "2024-05-07 15:26:12",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-27b60958-e576-4803-8cd0-dcfcd3ebf978"
                //     },
                //     {
                //         "id": 14,
                //         "name": "06. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 10,
                //         "file": "document-ed4ebebf-073d-4120-b8c0-a190a008d966",
                //         "created_at": "2024-05-07 15:26:29",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ed4ebebf-073d-4120-b8c0-a190a008d966"
                //     },
                //     {
                //         "id": 16,
                //         "name": "07. DECISÃO",
                //         "order": 11,
                //         "file": "document-3ebb7641-339a-477f-a598-c6f09372bc8e",
                //         "created_at": "2024-05-07 15:29:05",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-3ebb7641-339a-477f-a598-c6f09372bc8e"
                //     },
                //     {
                //         "id": 21,
                //         "name": "08. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 12,
                //         "file": "document-8390d8a2-a969-4461-abc5-16d3b1c7d55a",
                //         "created_at": "2024-05-07 15:52:17",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8390d8a2-a969-4461-abc5-16d3b1c7d55a"
                //     },
                //     {
                //         "id": 22,
                //         "name": "09. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 13,
                //         "file": "document-0162392a-c366-4c2f-b481-b356c17df9a7",
                //         "created_at": "2024-05-07 15:52:29",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0162392a-c366-4c2f-b481-b356c17df9a7"
                //     },
                //     {
                //         "id": 23,
                //         "name": "10. PRIMEIRA MANIFESTAÇÃO DA ADMINISTRAÇÃO JUDICIAL",
                //         "order": 14,
                //         "file": "document-201458f9-63a2-419e-9db2-e88e45f026b7",
                //         "created_at": "2024-05-07 15:52:39",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-201458f9-63a2-419e-9db2-e88e45f026b7"
                //     },
                //     {
                //         "id": 24,
                //         "name": "11. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 15,
                //         "file": "document-346cfafe-3daf-41b0-9782-6d9d3172670d",
                //         "created_at": "2024-05-07 15:52:53",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-346cfafe-3daf-41b0-9782-6d9d3172670d"
                //     },
                //     {
                //         "id": 25,
                //         "name": "12. MANIFESTAÇÃO MPMG",
                //         "order": 16,
                //         "file": "document-5d6eeb87-f99d-4267-a56f-44bea0803482",
                //         "created_at": "2024-05-07 15:53:04",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5d6eeb87-f99d-4267-a56f-44bea0803482"
                //     },
                //     {
                //         "id": 26,
                //         "name": "13. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 17,
                //         "file": "document-8763f571-e5a5-4083-9392-d5e785dcbb8b",
                //         "created_at": "2024-05-07 15:53:24",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8763f571-e5a5-4083-9392-d5e785dcbb8b"
                //     },
                //     {
                //         "id": 27,
                //         "name": "14. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 18,
                //         "file": "document-75e58081-0a96-4ea1-be95-956ca88fbc95",
                //         "created_at": "2024-05-07 15:53:36",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-75e58081-0a96-4ea1-be95-956ca88fbc95"
                //     },
                //     {
                //         "id": 28,
                //         "name": "15. MANIFESTAÇÃO DECISÃO LIMINAR CONSTATAÇÃO PRÉVIA",
                //         "order": 19,
                //         "file": "document-8ee9bb86-6cca-4e77-b0e4-94e3f8e5d77b",
                //         "created_at": "2024-05-07 15:53:50",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8ee9bb86-6cca-4e77-b0e4-94e3f8e5d77b"
                //     },
                //     {
                //         "id": 29,
                //         "name": "16. DECISÃO AGRAVO DE INSTRUMENTO DO MP",
                //         "order": 20,
                //         "file": "document-93c5633a-ac1c-44be-88ed-1092e84e1675",
                //         "created_at": "2024-05-07 15:54:04",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-93c5633a-ac1c-44be-88ed-1092e84e1675"
                //     },
                //     {
                //         "id": 30,
                //         "name": "17. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 21,
                //         "file": "document-091e542d-8d18-4655-80f9-79e9021c973e",
                //         "created_at": "2024-05-07 15:54:18",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-091e542d-8d18-4655-80f9-79e9021c973e"
                //     },
                //     {
                //         "id": 31,
                //         "name": "18. MANIFESTAÇÃO DOS NOMEADOS PARA CONSTATAÇÃO PRÉVIA NO AGRAVO",
                //         "order": 22,
                //         "file": "document-a4c86de4-ed23-46f8-afa2-3fda6ab9dc22",
                //         "created_at": "2024-05-07 16:50:35",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-a4c86de4-ed23-46f8-afa2-3fda6ab9dc22"
                //     },
                //     {
                //         "id": 32,
                //         "name": "19. SEGUNDA MANIFESTAÇÃO DA ADMINISTRAÇÃO JUDICIAL",
                //         "order": 23,
                //         "file": "document-2eedac79-eea3-4e48-9c36-b2f8186957be",
                //         "created_at": "2024-05-07 16:52:44",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-2eedac79-eea3-4e48-9c36-b2f8186957be"
                //     },
                //     {
                //         "id": 33,
                //         "name": "20. DECISÃO AGRAVO BANCO DO BRASIL 1.0000.23.231435-1001",
                //         "order": 24,
                //         "file": "document-1221427d-b595-4eb1-9af7-aabe8c3e26f3",
                //         "created_at": "2024-05-07 16:56:25",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1221427d-b595-4eb1-9af7-aabe8c3e26f3"
                //     },
                //     {
                //         "id": 34,
                //         "name": "21. MANIFESTAÇÃO ITAU UNIBANCO SA",
                //         "order": 25,
                //         "file": "document-704f1511-cafa-43ac-9501-32063637303a",
                //         "created_at": "2024-05-07 16:57:00",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-704f1511-cafa-43ac-9501-32063637303a"
                //     },
                //     {
                //         "id": 35,
                //         "name": "22. MANIFESTAÇÃO MPMG IMPEDIMENTO",
                //         "order": 26,
                //         "file": "document-736a66f1-04d7-4c31-a084-db19321ef4af",
                //         "created_at": "2024-05-07 16:57:18",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-736a66f1-04d7-4c31-a084-db19321ef4af"
                //     },
                //     {
                //         "id": 36,
                //         "name": "23. DECISÃO SUSPENSÃO",
                //         "order": 27,
                //         "file": "document-a5272448-cc53-4a88-89c1-32a55bd69165",
                //         "created_at": "2024-05-07 16:57:51",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-a5272448-cc53-4a88-89c1-32a55bd69165"
                //     },
                //     {
                //         "id": 37,
                //         "name": "24. DECISÃO AGRAVO CONSTATAÇÃO 12251967-29.2023.8.13.0000",
                //         "order": 28,
                //         "file": "document-cd2ae7ad-d98f-413c-875e-b87d019cdf36",
                //         "created_at": "2024-05-07 16:58:11",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-cd2ae7ad-d98f-413c-875e-b87d019cdf36"
                //     },
                //     {
                //         "id": 38,
                //         "name": "25. AGRAVO INTERNO 123 CONTRA BANCO DO BRASIL",
                //         "order": 29,
                //         "file": "document-018f3948-efed-4140-8822-ce91ead1fb9f",
                //         "created_at": "2024-05-07 16:58:40",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-018f3948-efed-4140-8822-ce91ead1fb9f"
                //     },
                //     {
                //         "id": 39,
                //         "name": "26. MANIFESTAÇÃO RECUPERANDA ADITAMENTO INICIAL",
                //         "order": 30,
                //         "file": "document-09a7199b-ac93-47e9-b922-01b5859c356e",
                //         "created_at": "2024-05-07 16:59:17",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-09a7199b-ac93-47e9-b922-01b5859c356e"
                //     },
                //     {
                //         "id": 40,
                //         "name": "27. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 31,
                //         "file": "document-d62ae2d8-da7e-40cd-a66c-bcec6b973339",
                //         "created_at": "2024-05-07 17:00:36",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-d62ae2d8-da7e-40cd-a66c-bcec6b973339"
                //     },
                //     {
                //         "id": 41,
                //         "name": "28. DECISÃO CONSTATAÇÃO PRÉVIA",
                //         "order": 32,
                //         "file": "document-63bb382d-3217-45e0-9977-1c1a0fc8df5e",
                //         "created_at": "2024-05-07 17:00:52",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-63bb382d-3217-45e0-9977-1c1a0fc8df5e"
                //     },
                //     {
                //         "id": 42,
                //         "name": "29. EMBARGOS DE DECLARAÇÃO MINISTÉRIO PÚBLICO",
                //         "order": 33,
                //         "file": "document-17fdd1f0-ed5f-4deb-880a-53a702b33ace",
                //         "created_at": "2024-05-07 17:01:16",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-17fdd1f0-ed5f-4deb-880a-53a702b33ace"
                //     },
                //     {
                //         "id": 43,
                //         "name": "30. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 34,
                //         "file": "document-12681ab1-8e16-4ae8-b622-076925e13676",
                //         "created_at": "2024-05-07 17:01:38",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-12681ab1-8e16-4ae8-b622-076925e13676"
                //     },
                //     {
                //         "id": 44,
                //         "name": "31. TERCEIRA MANIFESTAÇÃO DA ADMINISTRAÇÃO JUDICIAL",
                //         "order": 35,
                //         "file": "document-5258fd6f-7d2d-4b05-a9be-eced6563dad4",
                //         "created_at": "2024-05-07 17:01:54",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5258fd6f-7d2d-4b05-a9be-eced6563dad4"
                //     },
                //     {
                //         "id": 45,
                //         "name": "32. MANIFESTAÇÃO BANCO ITAÚ",
                //         "order": 36,
                //         "file": "document-bec4672f-f039-44c8-b972-439ffb83ea4c",
                //         "created_at": "2024-05-07 17:02:26",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-bec4672f-f039-44c8-b972-439ffb83ea4c"
                //     },
                //     {
                //         "id": 46,
                //         "name": "33. PETIÇÃO MASTERCARD",
                //         "order": 37,
                //         "file": "document-d2d0bb50-d2f5-4158-9d95-d5cbcaee34be",
                //         "created_at": "2024-05-07 17:02:44",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-d2d0bb50-d2f5-4158-9d95-d5cbcaee34be"
                //     },
                //     {
                //         "id": 47,
                //         "name": "34. MANIFESTAÇÃO MPMG",
                //         "order": 38,
                //         "file": "document-8145820d-68e2-41dd-940a-abae2cb151c5",
                //         "created_at": "2024-05-07 17:03:03",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8145820d-68e2-41dd-940a-abae2cb151c5"
                //     },
                //     {
                //         "id": 48,
                //         "name": "35. EMBARGOS DE DECLARAÇÃO CAROLINE ALMEIDA FERREIRA DOS SANTOS",
                //         "order": 39,
                //         "file": "document-11301a47-772f-4659-bf18-7f6e16697100",
                //         "created_at": "2024-05-07 17:03:20",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-11301a47-772f-4659-bf18-7f6e16697100"
                //     },
                //     {
                //         "id": 49,
                //         "name": "36. DECISÃO INOCÊNCIO",
                //         "order": 40,
                //         "file": "document-2bfcebc7-c53b-49df-9689-dff8a6e4ba86",
                //         "created_at": "2024-05-07 17:04:06",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-2bfcebc7-c53b-49df-9689-dff8a6e4ba86"
                //     },
                //     {
                //         "id": 50,
                //         "name": "37. MANIFESTAÇÃO JULIANA MORAIS E KPMG",
                //         "order": 41,
                //         "file": "document-17296157-1183-41bc-a856-02223cda7350",
                //         "created_at": "2024-05-07 17:04:19",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-17296157-1183-41bc-a856-02223cda7350"
                //     },
                //     {
                //         "id": 51,
                //         "name": "38. MANIFESTAÇÃO ACEITE INOCENCIO",
                //         "order": 42,
                //         "file": "document-14c0dd57-4e18-4ad5-83b4-aa75903ebfc0",
                //         "created_at": "2024-05-07 17:04:36",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-14c0dd57-4e18-4ad5-83b4-aa75903ebfc0"
                //     },
                //     {
                //         "id": 52,
                //         "name": "39. TERMO DE COMPROMISSO INOCENCIO",
                //         "order": 43,
                //         "file": "document-0af0fa7f-949e-4399-a554-541c0281a810",
                //         "created_at": "2024-05-07 17:04:54",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0af0fa7f-949e-4399-a554-541c0281a810"
                //     },
                //     {
                //         "id": 53,
                //         "name": "40. EMBARGOS DE DECLARAÇÃO 123",
                //         "order": 44,
                //         "file": "document-6812544e-9674-43a6-be0c-a8b7795fbea0",
                //         "created_at": "2024-05-07 17:05:10",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6812544e-9674-43a6-be0c-a8b7795fbea0"
                //     },
                //     {
                //         "id": 54,
                //         "name": "41. EMBARGOS DE DECLARAÇÃO MASTERCARD",
                //         "order": 45,
                //         "file": "document-4200f67f-2f1e-48e8-b290-98d06e388728",
                //         "created_at": "2024-05-07 17:05:38",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4200f67f-2f1e-48e8-b290-98d06e388728"
                //     },
                //     {
                //         "id": 55,
                //         "name": "42. MANIFESTAÇÃO RECUPERANDA RESPOSTA ED MP",
                //         "order": 46,
                //         "file": "document-42413a70-49fc-4400-af1f-660e84decb36",
                //         "created_at": "2024-05-07 17:06:32",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-42413a70-49fc-4400-af1f-660e84decb36"
                //     },
                //     {
                //         "id": 56,
                //         "name": "43. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 47,
                //         "file": "document-94f6ba2f-3a72-44c0-9add-6d406a73f258",
                //         "created_at": "2024-05-07 17:06:52",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-94f6ba2f-3a72-44c0-9add-6d406a73f258"
                //     },
                //     {
                //         "id": 57,
                //         "name": "44. EMBARGOS DE DECLARAÇÃO MINISTÉRIO PÚBLICO",
                //         "order": 48,
                //         "file": "document-e463439e-1061-49ed-8825-14f622235687",
                //         "created_at": "2024-05-07 17:07:07",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e463439e-1061-49ed-8825-14f622235687"
                //     },
                //     {
                //         "id": 58,
                //         "name": "45. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 49,
                //         "file": "document-4eff6fe2-f959-4939-81b6-c7ffc809d1b6",
                //         "created_at": "2024-05-07 17:07:30",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4eff6fe2-f959-4939-81b6-c7ffc809d1b6"
                //     },
                //     {
                //         "id": 59,
                //         "name": "46. MANIFESTAÇÃO SERASA",
                //         "order": 50,
                //         "file": "document-e05ad019-fc31-4488-81cd-52549686d2b8",
                //         "created_at": "2024-05-07 17:08:19",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e05ad019-fc31-4488-81cd-52549686d2b8"
                //     },
                //     {
                //         "id": 60,
                //         "name": "47. MANIFESTAÇÃO ONBEHALF AUDITORES",
                //         "order": 51,
                //         "file": "document-eb7428f6-3488-4a62-bd31-6e29cb5df55f",
                //         "created_at": "2024-05-07 17:08:35",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-eb7428f6-3488-4a62-bd31-6e29cb5df55f"
                //     },
                //     {
                //         "id": 61,
                //         "name": "48. MANIFESTAÇÃO RECUPERANDA COPASA",
                //         "order": 52,
                //         "file": "document-5b3acd16-0a4f-4324-a999-a4be1fe6bec5",
                //         "created_at": "2024-05-07 17:08:55",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5b3acd16-0a4f-4324-a999-a4be1fe6bec5"
                //     },
                //     {
                //         "id": 63,
                //         "name": "49. MANIFESTAÇÃO RECUPERANDA RELAÇÃO DE CREDORES",
                //         "order": 53,
                //         "file": "document-78e1fb0e-ef95-4b30-bbad-29784c0592a3",
                //         "created_at": "2024-05-08 11:46:31",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-78e1fb0e-ef95-4b30-bbad-29784c0592a3"
                //     },
                //     {
                //         "id": 64,
                //         "name": "50. MANIFESTAÇÃO PERITO SUBSTITUÍDO",
                //         "order": 54,
                //         "file": "document-02d493be-02ff-4a6b-b821-3d738379d0ce",
                //         "created_at": "2024-05-08 15:34:08",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-02d493be-02ff-4a6b-b821-3d738379d0ce"
                //     },
                //     {
                //         "id": 65,
                //         "name": "51. MANIFESTAÇÃO RECUPERANDA RELAÇÃO DE AÇÕES",
                //         "order": 55,
                //         "file": "document-60e3743b-732e-4a5d-a896-3e0badc88c62",
                //         "created_at": "2024-05-08 15:34:40",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-60e3743b-732e-4a5d-a896-3e0badc88c62"
                //     },
                //     {
                //         "id": 66,
                //         "name": "52. MANIFESTAÇÃO SERASA RETOMADA SERVIÇOS ESSENCIAIS",
                //         "order": 56,
                //         "file": "document-7cb5a3b7-fcde-472b-a423-1f45bdc40af7",
                //         "created_at": "2024-05-08 15:34:58",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7cb5a3b7-fcde-472b-a423-1f45bdc40af7"
                //     },
                //     {
                //         "id": 67,
                //         "name": "53. MANIFESTAÇÃO CÂMBIO MILHAS",
                //         "order": 57,
                //         "file": "document-dff9978c-c2d7-4754-87c3-7b0df580f9aa",
                //         "created_at": "2024-05-08 15:35:18",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-dff9978c-c2d7-4754-87c3-7b0df580f9aa"
                //     },
                //     {
                //         "id": 68,
                //         "name": "54. DECISÃO",
                //         "order": 58,
                //         "file": "document-92fb21eb-ed46-4ac1-8e46-4770d41f9b34",
                //         "created_at": "2024-05-08 15:35:43",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-92fb21eb-ed46-4ac1-8e46-4770d41f9b34"
                //     },
                //     {
                //         "id": 69,
                //         "name": "55. PETIÇÃO SANTANDER",
                //         "order": 59,
                //         "file": "document-f9f49ca4-ffe9-4137-ba21-c992d09ab96e",
                //         "created_at": "2024-05-08 15:35:58",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-f9f49ca4-ffe9-4137-ba21-c992d09ab96e"
                //     },
                //     {
                //         "id": 70,
                //         "name": "56. QUARTA MANIFESTAÇÃO DA ADMINISTRAÇÃO JUDICIAL",
                //         "order": 60,
                //         "file": "document-4a0a3d21-bf4e-4fdc-ba2c-afb04faaf797",
                //         "created_at": "2024-05-08 15:36:15",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4a0a3d21-bf4e-4fdc-ba2c-afb04faaf797"
                //     },
                //     {
                //         "id": 71,
                //         "name": "57. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 61,
                //         "file": "document-0bc6e5ab-f15a-464f-913b-71df53783ec9",
                //         "created_at": "2024-05-08 15:36:32",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0bc6e5ab-f15a-464f-913b-71df53783ec9"
                //     },
                //     {
                //         "id": 72,
                //         "name": "58. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 62,
                //         "file": "document-b22ccc65-e6bd-4ab3-9bdc-d9d3217b86fd",
                //         "created_at": "2024-05-08 15:38:02",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b22ccc65-e6bd-4ab3-9bdc-d9d3217b86fd"
                //     },
                //     {
                //         "id": 73,
                //         "name": "58. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 63,
                //         "file": "document-86327f9d-b6ea-42b8-9018-8e8b67fea02c",
                //         "created_at": "2024-05-08 15:46:57",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-86327f9d-b6ea-42b8-9018-8e8b67fea02c"
                //     },
                //     {
                //         "id": 74,
                //         "name": "59. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 64,
                //         "file": "document-562d49c3-7817-4ce6-9a89-a442bb81303d",
                //         "created_at": "2024-05-08 15:47:20",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-562d49c3-7817-4ce6-9a89-a442bb81303d"
                //     },
                //     {
                //         "id": 75,
                //         "name": "60. MANIFESTAÇÃO RECUPERANDAS LEVANTAMENTO DE VALORES",
                //         "order": 65,
                //         "file": "document-0554323f-c1ae-417c-9969-2423f6bff856",
                //         "created_at": "2024-05-08 15:47:42",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0554323f-c1ae-417c-9969-2423f6bff856"
                //     },
                //     {
                //         "id": 76,
                //         "name": "61. CONSTATAÇÃO PRÉVIA MAX E LH",
                //         "order": 66,
                //         "file": "document-d9121f4a-1498-4ee9-9b14-b7f1376bbedd",
                //         "created_at": "2024-05-08 15:48:54",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-d9121f4a-1498-4ee9-9b14-b7f1376bbedd"
                //     },
                //     {
                //         "id": 77,
                //         "name": "62. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 67,
                //         "file": "document-441fc435-1762-42d5-89f1-2573942bd4c9",
                //         "created_at": "2024-05-08 15:49:44",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-441fc435-1762-42d5-89f1-2573942bd4c9"
                //     },
                //     {
                //         "id": 78,
                //         "name": "63. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 68,
                //         "file": "document-eac3a3b7-5e1b-475f-bc04-9cb52241a8a0",
                //         "created_at": "2024-05-08 15:50:00",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-eac3a3b7-5e1b-475f-bc04-9cb52241a8a0"
                //     },
                //     {
                //         "id": 79,
                //         "name": "64. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 69,
                //         "file": "document-07c3b26c-2ef2-4476-9472-7910d8eb3539",
                //         "created_at": "2024-05-08 15:50:34",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-07c3b26c-2ef2-4476-9472-7910d8eb3539"
                //     },
                //     {
                //         "id": 80,
                //         "name": "65. DECISÃO",
                //         "order": 70,
                //         "file": "document-b8ea8e0e-ecd5-4e5c-9b26-69e8eb4765a3",
                //         "created_at": "2024-05-08 15:50:50",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b8ea8e0e-ecd5-4e5c-9b26-69e8eb4765a3"
                //     },
                //     {
                //         "id": 81,
                //         "name": "66. MANIFESTAÇÃO RECUPERANDA LEVANTAMENTO DE VALORES",
                //         "order": 71,
                //         "file": "document-affd9a02-6af5-4bff-8e18-b951e5fa2699",
                //         "created_at": "2024-05-08 15:51:07",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-affd9a02-6af5-4bff-8e18-b951e5fa2699"
                //     },
                //     {
                //         "id": 82,
                //         "name": "67. MANIFESTAÇÃO MPMG",
                //         "order": 72,
                //         "file": "document-e6ba0fb4-c0fd-4bca-8517-065a4c5236b1",
                //         "created_at": "2024-05-08 15:51:21",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e6ba0fb4-c0fd-4bca-8517-065a4c5236b1"
                //     },
                //     {
                //         "id": 83,
                //         "name": "68. MANIFESTAÇÃO JULIANA E KPMG APURAÇÃO PRESTAÇÃO DE CONTAS",
                //         "order": 73,
                //         "file": "document-acd6b4ac-4d8c-422a-a8ff-8349c3d20835",
                //         "created_at": "2024-05-08 15:51:37",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-acd6b4ac-4d8c-422a-a8ff-8349c3d20835"
                //     },
                //     {
                //         "id": 84,
                //         "name": "69. MANIFESTAÇÃO RECUPERANDA SISTEMA PJE",
                //         "order": 74,
                //         "file": "document-3a500060-fcfd-4a97-aa4f-4ece99d25159",
                //         "created_at": "2024-05-08 15:51:52",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-3a500060-fcfd-4a97-aa4f-4ece99d25159"
                //     },
                //     {
                //         "id": 85,
                //         "name": "70. MANIFESTAÇÃO ASSOCIAÇÃO BRASILEIRA DE LIBERDADE ECONÔMICA - ABLE",
                //         "order": 75,
                //         "file": "document-225da822-277f-45be-a3e1-8f6d172a6a8c",
                //         "created_at": "2024-05-08 15:52:07",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-225da822-277f-45be-a3e1-8f6d172a6a8c"
                //     },
                //     {
                //         "id": 86,
                //         "name": "71. MANIFESTAÇÃO SANTANDER",
                //         "order": 76,
                //         "file": "document-7f743176-b4f1-4fdd-b32d-c71221626a50",
                //         "created_at": "2024-05-08 15:52:46",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7f743176-b4f1-4fdd-b32d-c71221626a50"
                //     },
                //     {
                //         "id": 87,
                //         "name": "72. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 77,
                //         "file": "document-33ac8d05-4ad2-4374-a0da-d885b8f378ea",
                //         "created_at": "2024-05-08 15:53:00",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-33ac8d05-4ad2-4374-a0da-d885b8f378ea"
                //     },
                //     {
                //         "id": 88,
                //         "name": "73. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 78,
                //         "file": "document-8dff587c-d113-42eb-a23d-0283707aade9",
                //         "created_at": "2024-05-08 15:53:13",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8dff587c-d113-42eb-a23d-0283707aade9"
                //     },
                //     {
                //         "id": 89,
                //         "name": "74. MANIFESTAÇÃO ITAÚ UNIBANCO S.A.",
                //         "order": 79,
                //         "file": "document-04d1e328-106e-4684-a113-02c1abda0ea4",
                //         "created_at": "2024-05-08 15:53:26",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-04d1e328-106e-4684-a113-02c1abda0ea4"
                //     },
                //     {
                //         "id": 90,
                //         "name": "75. DECISÃO AGRAVO RETOMADA RJ",
                //         "order": 80,
                //         "file": "document-155586e4-d95c-4719-90ab-0a5c69471c38",
                //         "created_at": "2024-05-08 15:53:42",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-155586e4-d95c-4719-90ab-0a5c69471c38"
                //     },
                //     {
                //         "id": 91,
                //         "name": "76. MANIFESTAÇÃO PROCON",
                //         "order": 81,
                //         "file": "document-f2e62cf8-b4a0-400a-bb06-e1c66e1a028e",
                //         "created_at": "2024-05-08 15:53:58",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-f2e62cf8-b4a0-400a-bb06-e1c66e1a028e"
                //     },
                //     {
                //         "id": 92,
                //         "name": "77. DESPACHO",
                //         "order": 82,
                //         "file": "document-1d656456-3ef4-4f72-ae72-b125c37945df",
                //         "created_at": "2024-05-08 15:54:18",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1d656456-3ef4-4f72-ae72-b125c37945df"
                //     },
                //     {
                //         "id": 93,
                //         "name": "78. MANIFESTAÇÃO JAPUR E INOCENCIO",
                //         "order": 83,
                //         "file": "document-7a630843-657b-4975-946d-8a50b1334b25",
                //         "created_at": "2024-05-08 15:54:32",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7a630843-657b-4975-946d-8a50b1334b25"
                //     },
                //     {
                //         "id": 94,
                //         "name": "79. MANIFESTAÇÃO PBB",
                //         "order": 84,
                //         "file": "document-ab786321-9998-4673-aa4b-d3ffde59f297",
                //         "created_at": "2024-05-08 15:54:50",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ab786321-9998-4673-aa4b-d3ffde59f297"
                //     },
                //     {
                //         "id": 95,
                //         "name": "80. 2° RELATÓRIO GASTOS JULIANA E KPMG",
                //         "order": 85,
                //         "file": "document-62094715-b65c-4d00-990b-b29598a9fc00",
                //         "created_at": "2024-05-08 15:55:05",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-62094715-b65c-4d00-990b-b29598a9fc00"
                //     },
                //     {
                //         "id": 96,
                //         "name": "81. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 86,
                //         "file": "document-9679be0f-22d0-4d29-b8fd-cb968c3b7089",
                //         "created_at": "2024-05-08 15:55:29",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-9679be0f-22d0-4d29-b8fd-cb968c3b7089"
                //     },
                //     {
                //         "id": 97,
                //         "name": "82. MANIFESTAÇÃO IBRACI",
                //         "order": 87,
                //         "file": "document-aad02f01-9d26-4bb3-ac1f-e70d131e9e5c",
                //         "created_at": "2024-05-08 15:55:42",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-aad02f01-9d26-4bb3-ac1f-e70d131e9e5c"
                //     },
                //     {
                //         "id": 98,
                //         "name": "83. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 88,
                //         "file": "document-ac82e14d-b19e-4cdc-9f8e-48a4b78b755a",
                //         "created_at": "2024-05-08 15:56:25",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ac82e14d-b19e-4cdc-9f8e-48a4b78b755a"
                //     },
                //     {
                //         "id": 99,
                //         "name": "84. MANIFESTAÇÃO IBRACI",
                //         "order": 89,
                //         "file": "document-2860cb59-c648-4793-88a0-80f63eadd647",
                //         "created_at": "2024-05-08 15:56:56",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-2860cb59-c648-4793-88a0-80f63eadd647"
                //     },
                //     {
                //         "id": 100,
                //         "name": "85. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 90,
                //         "file": "document-6df425bb-e659-40da-b99b-bd41bb5844ad",
                //         "created_at": "2024-05-08 15:57:08",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6df425bb-e659-40da-b99b-bd41bb5844ad"
                //     },
                //     {
                //         "id": 101,
                //         "name": "86. MANIFESTAÇÃO IBRACI",
                //         "order": 91,
                //         "file": "document-2feed64f-da22-4d7b-bf0e-6c5e75f8c583",
                //         "created_at": "2024-05-08 15:57:23",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-2feed64f-da22-4d7b-bf0e-6c5e75f8c583"
                //     },
                //     {
                //         "id": 102,
                //         "name": "87. MANIFESTAÇÃO MINISTÉRIO PÚBLICO",
                //         "order": 92,
                //         "file": "document-80471935-2c84-4669-855f-92e476cb3e51",
                //         "created_at": "2024-05-08 15:57:36",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-80471935-2c84-4669-855f-92e476cb3e51"
                //     },
                //     {
                //         "id": 103,
                //         "name": "88. MANIFESTAÇÃO ESTADO DE MINAS GERAIS",
                //         "order": 93,
                //         "file": "document-0e938d0c-d5d4-4f3e-8cc8-ec81aeefa846",
                //         "created_at": "2024-05-08 15:58:00",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0e938d0c-d5d4-4f3e-8cc8-ec81aeefa846"
                //     },
                //     {
                //         "id": 104,
                //         "name": "89. DECISÃO SUSPENSÃO",
                //         "order": 94,
                //         "file": "document-0ede0bec-46f7-404d-a50b-9166b6ea816d",
                //         "created_at": "2024-05-08 15:58:15",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0ede0bec-46f7-404d-a50b-9166b6ea816d"
                //     },
                //     {
                //         "id": 105,
                //         "name": "90. MANIFESTAÇÃO IBRACI",
                //         "order": 95,
                //         "file": "document-8bcc0897-0d76-423f-9708-c70387c59f49",
                //         "created_at": "2024-05-08 15:58:27",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8bcc0897-0d76-423f-9708-c70387c59f49"
                //     },
                //     {
                //         "id": 106,
                //         "name": "91. MANIFESTAÇÃO MINISTÉRIO PÚBLICO",
                //         "order": 96,
                //         "file": "document-80703f03-c006-4c1c-b6d2-d3b4118c1639",
                //         "created_at": "2024-05-08 15:58:41",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-80703f03-c006-4c1c-b6d2-d3b4118c1639"
                //     },
                //     {
                //         "id": 107,
                //         "name": "92. 3° RELATÓRIO GASTOS JULIANA E KPMG",
                //         "order": 97,
                //         "file": "document-97414ec0-f796-41f0-bf79-3e5c30e26c5a",
                //         "created_at": "2024-05-08 15:58:57",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-97414ec0-f796-41f0-bf79-3e5c30e26c5a"
                //     },
                //     {
                //         "id": 108,
                //         "name": "93. MANIFESTAÇÃO ITAÚ UNIBANCO",
                //         "order": 98,
                //         "file": "document-e739882f-f9a5-4cf6-964b-5793c8b0a110",
                //         "created_at": "2024-05-08 15:59:08",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e739882f-f9a5-4cf6-964b-5793c8b0a110"
                //     },
                //     {
                //         "id": 109,
                //         "name": "94. CERTIDÃO ALVARÁ RECUPERANDAS",
                //         "order": 99,
                //         "file": "document-fc01e16f-dd30-4785-8d4f-06d14d73520b",
                //         "created_at": "2024-05-08 15:59:19",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-fc01e16f-dd30-4785-8d4f-06d14d73520b"
                //     },
                //     {
                //         "id": 110,
                //         "name": "95. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 100,
                //         "file": "document-ab55616b-6195-4f51-8db9-00176932df6f",
                //         "created_at": "2024-05-08 15:59:44",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ab55616b-6195-4f51-8db9-00176932df6f"
                //     },
                //     {
                //         "id": 111,
                //         "name": "96. MANIFESTAÇÃO RECUPERANDA",
                //         "order": 101,
                //         "file": "document-2098af24-dcf4-4408-8f5d-2367a738d190",
                //         "created_at": "2024-05-08 15:59:57",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-2098af24-dcf4-4408-8f5d-2367a738d190"
                //     },
                //     {
                //         "id": 112,
                //         "name": "97. MANIFESTAÇÃO GETNET",
                //         "order": 102,
                //         "file": "document-07da1f8a-7466-4e44-8373-305b8337ac22",
                //         "created_at": "2024-05-08 16:00:11",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-07da1f8a-7466-4e44-8373-305b8337ac22"
                //     },
                //     {
                //         "id": 113,
                //         "name": "98. MANIFESTAÇÃO MERCADO PAGO",
                //         "order": 103,
                //         "file": "document-c15801ce-abf6-4679-9482-e758437583b2",
                //         "created_at": "2024-05-08 16:00:30",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-c15801ce-abf6-4679-9482-e758437583b2"
                //     },
                //     {
                //         "id": 114,
                //         "name": "99. MANIFESTAÇÃO AJ CIENCIA",
                //         "order": 104,
                //         "file": "document-f12036be-3d11-4cfb-8177-7b95d9d4dd57",
                //         "created_at": "2024-05-08 16:00:48",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-f12036be-3d11-4cfb-8177-7b95d9d4dd57"
                //     },
                //     {
                //         "id": 115,
                //         "name": "100. DECISÃO AGRAVO INADEC PROSSEGUIMENTO",
                //         "order": 105,
                //         "file": "document-28005ba5-6f4b-4255-8b0b-8376933042be",
                //         "created_at": "2024-05-08 16:00:59",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-28005ba5-6f4b-4255-8b0b-8376933042be"
                //     },
                //     {
                //         "id": 116,
                //         "name": "101. DECISÃO",
                //         "order": 106,
                //         "file": "document-52059ff4-9bcd-4163-8dd6-c08106f9ea2c",
                //         "created_at": "2024-05-08 16:01:47",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-52059ff4-9bcd-4163-8dd6-c08106f9ea2c"
                //     },
                //     {
                //         "id": 117,
                //         "name": "102. MANIFESTAÇÃO INADEC",
                //         "order": 107,
                //         "file": "document-852ef9c2-901b-4e7d-a55a-e6dae7c735ca",
                //         "created_at": "2024-05-08 16:02:01",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-852ef9c2-901b-4e7d-a55a-e6dae7c735ca"
                //     },
                //     {
                //         "id": 118,
                //         "name": "103. MANIFESTAÇÃO ITAÚ",
                //         "order": 108,
                //         "file": "document-b5ebe47b-37cb-4e24-af97-edc800588f02",
                //         "created_at": "2024-05-08 16:02:27",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b5ebe47b-37cb-4e24-af97-edc800588f02"
                //     },
                //     {
                //         "id": 119,
                //         "name": "104. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 109,
                //         "file": "document-74ea8bf3-59d2-4642-86e1-ea04610b717a",
                //         "created_at": "2024-05-08 16:03:10",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-74ea8bf3-59d2-4642-86e1-ea04610b717a"
                //     },
                //     {
                //         "id": 120,
                //         "name": "105. MANIFESTAÇÃO PROCON SP",
                //         "order": 110,
                //         "file": "document-ebd11bf8-ad56-4937-a42a-ca0fcab2b677",
                //         "created_at": "2024-05-08 16:03:31",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ebd11bf8-ad56-4937-a42a-ca0fcab2b677"
                //     },
                //     {
                //         "id": 121,
                //         "name": "106. MANIFESTAÇÃO SANTADER",
                //         "order": 111,
                //         "file": "document-95cdb47d-69a8-496c-a3b0-2bb5f5e1aa69",
                //         "created_at": "2024-05-08 16:04:28",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-95cdb47d-69a8-496c-a3b0-2bb5f5e1aa69"
                //     },
                //     {
                //         "id": 122,
                //         "name": "107. TERMO DE COMPROMISSO JULIANA E KPMG",
                //         "order": 112,
                //         "file": "document-713b7890-194e-4c21-8b9d-90c41961eefe",
                //         "created_at": "2024-05-08 16:05:24",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-713b7890-194e-4c21-8b9d-90c41961eefe"
                //     },
                //     {
                //         "id": 123,
                //         "name": "108. MANIFESTAÇÃO MM E LH",
                //         "order": 113,
                //         "file": "document-61fc74cf-8fda-4a5b-8d35-e202a962b2cf",
                //         "created_at": "2024-05-08 16:07:22",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-61fc74cf-8fda-4a5b-8d35-e202a962b2cf"
                //     },
                //     {
                //         "id": 124,
                //         "name": "109. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 114,
                //         "file": "document-ab3bd379-e20a-485d-b83e-f382ddf99512",
                //         "created_at": "2024-05-08 16:07:37",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ab3bd379-e20a-485d-b83e-f382ddf99512"
                //     },
                //     {
                //         "id": 125,
                //         "name": "110. RELATÓRIO DE VISITA TÉCNICA JULIANA E KPMG",
                //         "order": 115,
                //         "file": "document-ccf704f6-df39-491f-8c5b-af69c8261882",
                //         "created_at": "2024-05-08 16:08:02",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ccf704f6-df39-491f-8c5b-af69c8261882"
                //     },
                //     {
                //         "id": 126,
                //         "name": "111. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 116,
                //         "file": "document-8ba66160-b3aa-41dc-9994-29a7a881df1f",
                //         "created_at": "2024-05-08 16:08:22",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8ba66160-b3aa-41dc-9994-29a7a881df1f"
                //     },
                //     {
                //         "id": 127,
                //         "name": "112. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 117,
                //         "file": "document-1ebd6d39-fe83-402b-8aaf-aac02209abd0",
                //         "created_at": "2024-05-08 16:08:36",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1ebd6d39-fe83-402b-8aaf-aac02209abd0"
                //     },
                //     {
                //         "id": 128,
                //         "name": "113. MANIFESTAÇÃO SANEAMENTO AJ",
                //         "order": 118,
                //         "file": "document-842be6b7-65bf-470b-a33f-ac88c0022fc9",
                //         "created_at": "2024-05-08 16:08:49",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-842be6b7-65bf-470b-a33f-ac88c0022fc9"
                //     },
                //     {
                //         "id": 129,
                //         "name": "114. RELATÓRIO DE ANÁLISE DE CONTAS JULIANA E KPMG",
                //         "order": 119,
                //         "file": "document-f7088125-0108-4eaf-b6f7-bc1e2d138e54",
                //         "created_at": "2024-05-08 16:09:01",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-f7088125-0108-4eaf-b6f7-bc1e2d138e54"
                //     },
                //     {
                //         "id": 130,
                //         "name": "115. DECISÃO",
                //         "order": 120,
                //         "file": "document-9e6c26a8-5757-4a1e-b16d-694fc68aaf9c",
                //         "created_at": "2024-05-08 16:09:13",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-9e6c26a8-5757-4a1e-b16d-694fc68aaf9c"
                //     },
                //     {
                //         "id": 131,
                //         "name": "116. EMBARGOS DE DECLARAÇÃO ITAÚ",
                //         "order": 121,
                //         "file": "document-482d9887-807f-4c9d-87ef-6ee886fb5a4d",
                //         "created_at": "2024-05-08 16:09:25",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-482d9887-807f-4c9d-87ef-6ee886fb5a4d"
                //     },
                //     {
                //         "id": 132,
                //         "name": "117. MANIFESTAÇÃO GETNET",
                //         "order": 122,
                //         "file": "document-e13dc4a9-076f-4e76-ba09-2562d3d0bcb9",
                //         "created_at": "2024-05-08 16:09:45",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e13dc4a9-076f-4e76-ba09-2562d3d0bcb9"
                //     },
                //     {
                //         "id": 133,
                //         "name": "118. MANIFESTAÇÃO GETNET",
                //         "order": 123,
                //         "file": "document-cfa6657d-21b6-447d-80f7-c98dc301b652",
                //         "created_at": "2024-05-08 16:09:59",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-cfa6657d-21b6-447d-80f7-c98dc301b652"
                //     },
                //     {
                //         "id": 134,
                //         "name": "119. RMA - JUL. A DEZ.2023",
                //         "order": 124,
                //         "file": "document-fc971def-0ff9-448f-b29f-0a8f64c2dbd7",
                //         "created_at": "2024-05-08 16:10:27",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-fc971def-0ff9-448f-b29f-0a8f64c2dbd7"
                //     },
                //     {
                //         "id": 135,
                //         "name": "120. MANIFESTAÇÃO MP",
                //         "order": 125,
                //         "file": "document-8f7e1dd5-4e8e-407a-a935-1b0cb543191c",
                //         "created_at": "2024-05-08 16:10:39",
                //         "updated_at": "2024-08-19 21:30:22",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8f7e1dd5-4e8e-407a-a935-1b0cb543191c"
                //     },
                //     {
                //         "id": 136,
                //         "name": "121. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 126,
                //         "file": "document-613c92fb-51c7-4c84-b4ae-ec5d35d92fbe",
                //         "created_at": "2024-05-08 16:11:14",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-613c92fb-51c7-4c84-b4ae-ec5d35d92fbe"
                //     },
                //     {
                //         "id": 137,
                //         "name": "122. MANIFESTAÇÃO AJ",
                //         "order": 127,
                //         "file": "document-de8e2252-e6fc-4f3b-a399-d8a33f5a6661",
                //         "created_at": "2024-05-08 16:11:31",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-de8e2252-e6fc-4f3b-a399-d8a33f5a6661"
                //     },
                //     {
                //         "id": 138,
                //         "name": "123. MANIFESTAÇÃO SERASA",
                //         "order": 128,
                //         "file": "document-d1f259ad-693a-4c76-9d2d-9f35771eeef0",
                //         "created_at": "2024-05-08 16:11:45",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-d1f259ad-693a-4c76-9d2d-9f35771eeef0"
                //     },
                //     {
                //         "id": 139,
                //         "name": "124. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 129,
                //         "file": "document-b830a097-2f37-4208-9cfe-a5f422c3f8ef",
                //         "created_at": "2024-05-08 16:12:00",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b830a097-2f37-4208-9cfe-a5f422c3f8ef"
                //     },
                //     {
                //         "id": 140,
                //         "name": "125. AGRAVO GETNET",
                //         "order": 130,
                //         "file": "document-445667c3-37b7-4871-8e4f-43772e5a9007",
                //         "created_at": "2024-05-08 16:12:18",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-445667c3-37b7-4871-8e4f-43772e5a9007"
                //     },
                //     {
                //         "id": 141,
                //         "name": "126. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 131,
                //         "file": "document-77662fab-1f0f-4469-b3c3-b5cd3ab7c4f1",
                //         "created_at": "2024-05-08 16:12:29",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-77662fab-1f0f-4469-b3c3-b5cd3ab7c4f1"
                //     },
                //     {
                //         "id": 142,
                //         "name": "127. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 132,
                //         "file": "document-1f4ba0b1-1a5d-4e80-9977-fd31be1bf291",
                //         "created_at": "2024-05-08 16:12:41",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1f4ba0b1-1a5d-4e80-9977-fd31be1bf291"
                //     },
                //     {
                //         "id": 144,
                //         "name": "128. DECISÃO DEFERIMENTO MAX E LH",
                //         "order": 133,
                //         "file": "document-78802c6b-e1bc-4080-a242-4f0e702c5e02",
                //         "created_at": "2024-05-08 19:08:37",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-78802c6b-e1bc-4080-a242-4f0e702c5e02"
                //     },
                //     {
                //         "id": 145,
                //         "name": "129. LISTA DE CREDORES 123",
                //         "order": 134,
                //         "file": "document-7b41be33-1d6e-49b5-a447-ea325818ed64",
                //         "created_at": "2024-05-15 18:03:38",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7b41be33-1d6e-49b5-a447-ea325818ed64"
                //     },
                //     {
                //         "id": 146,
                //         "name": "130. LISTA DE CREDORES 123",
                //         "order": 135,
                //         "file": "document-bf22d7b8-57a0-4f18-bfd6-491d34cefb34",
                //         "created_at": "2024-05-15 18:03:53",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-bf22d7b8-57a0-4f18-bfd6-491d34cefb34"
                //     },
                //     {
                //         "id": 147,
                //         "name": "131. LISTA DE CREDORES 123",
                //         "order": 136,
                //         "file": "document-cf7e00a8-ac4a-47b9-8ea9-5ea5287b5971",
                //         "created_at": "2024-05-15 18:04:32",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-cf7e00a8-ac4a-47b9-8ea9-5ea5287b5971"
                //     },
                //     {
                //         "id": 148,
                //         "name": "132. LISTA DE CREDORES MAX E LH",
                //         "order": 137,
                //         "file": "document-522ed0e7-353d-4e99-bac1-f3c7be3a7a82",
                //         "created_at": "2024-05-15 18:04:46",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-522ed0e7-353d-4e99-bac1-f3c7be3a7a82"
                //     },
                //     {
                //         "id": 149,
                //         "name": "133. CERTIDÃO PROMOÇÃO DOS DOCS SIGILOSOS",
                //         "order": 138,
                //         "file": "document-7d94c30f-dad8-442f-a184-722f67254e93",
                //         "created_at": "2024-05-15 18:05:15",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7d94c30f-dad8-442f-a184-722f67254e93"
                //     },
                //     {
                //         "id": 150,
                //         "name": "134. DESPACHO",
                //         "order": 139,
                //         "file": "document-aad04bc4-434c-4e1d-9188-c08ec592f1f4",
                //         "created_at": "2024-05-15 18:05:29",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-aad04bc4-434c-4e1d-9188-c08ec592f1f4"
                //     },
                //     {
                //         "id": 151,
                //         "name": "135. MANIFESTAÇÃO AJ",
                //         "order": 140,
                //         "file": "document-d6fc8945-8abe-47c6-999b-b072732c1cb2",
                //         "created_at": "2024-05-15 18:05:42",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-d6fc8945-8abe-47c6-999b-b072732c1cb2"
                //     },
                //     {
                //         "id": 152,
                //         "name": "136. TERMO DE COMPROMISSO PBB, BeJ e INOCENCIO",
                //         "order": 141,
                //         "file": "document-64842a51-2457-4334-a5ea-d9f2f8524ead",
                //         "created_at": "2024-05-15 18:06:08",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-64842a51-2457-4334-a5ea-d9f2f8524ead"
                //     },
                //     {
                //         "id": 153,
                //         "name": "137. MANIFESTAÇÃO MPMG",
                //         "order": 142,
                //         "file": "document-c85e9327-d158-4dbe-9b58-2912f844da1c",
                //         "created_at": "2024-05-15 18:06:20",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-c85e9327-d158-4dbe-9b58-2912f844da1c"
                //     },
                //     {
                //         "id": 154,
                //         "name": "138. RELATÓRIO GASTOS JULIANA E KPMG",
                //         "order": 143,
                //         "file": "document-6e774f13-93ee-4169-b94e-df6aaba3277c",
                //         "created_at": "2024-05-17 16:16:19",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6e774f13-93ee-4169-b94e-df6aaba3277c"
                //     },
                //     {
                //         "id": 155,
                //         "name": "139. MANIFESTAÇÃO AZUL",
                //         "order": 144,
                //         "file": "document-aba6fe2f-60c9-4b27-8b32-ccea9bdf4446",
                //         "created_at": "2024-05-20 16:30:42",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-aba6fe2f-60c9-4b27-8b32-ccea9bdf4446"
                //     },
                //     {
                //         "id": 156,
                //         "name": "140. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 145,
                //         "file": "document-8d7c22be-814c-4147-8e50-5fa54b23d1f0",
                //         "created_at": "2024-05-20 16:30:56",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8d7c22be-814c-4147-8e50-5fa54b23d1f0"
                //     },
                //     {
                //         "id": 157,
                //         "name": "141. PET 123 CONSOLIDAÇÃO SUBSTANCIAL E PAGAMENTO CLASSE I",
                //         "order": 146,
                //         "file": "document-5c91d790-bf50-44cd-8d0a-1af2ad41e195",
                //         "created_at": "2024-05-20 21:26:25",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5c91d790-bf50-44cd-8d0a-1af2ad41e195"
                //     },
                //     {
                //         "id": 158,
                //         "name": "142. RELAÇÃO NOMINAL DOS CREDORES UNITÁRIA 123",
                //         "order": 147,
                //         "file": "document-0bea1dd3-736c-4f18-86de-689610b25ab5",
                //         "created_at": "2024-05-20 21:26:38",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0bea1dd3-736c-4f18-86de-689610b25ab5"
                //     },
                //     {
                //         "id": 159,
                //         "name": "143. RELAÇÃO NOMINAL DOS CREDORES UNITÁRIA 123",
                //         "order": 148,
                //         "file": "document-b9fbb4f5-7352-4d14-a899-47676e6cb424",
                //         "created_at": "2024-05-20 21:26:50",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b9fbb4f5-7352-4d14-a899-47676e6cb424"
                //     },
                //     {
                //         "id": 160,
                //         "name": "144. RELAÇÃO NOMINAL DOS CREDORES UNITÁRIA 123",
                //         "order": 149,
                //         "file": "document-06124357-00fb-493e-9175-f8b6e16e8263",
                //         "created_at": "2024-05-20 21:27:01",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-06124357-00fb-493e-9175-f8b6e16e8263"
                //     },
                //     {
                //         "id": 161,
                //         "name": "145. EMBARGOS DE DECLARAÇÃO 123",
                //         "order": 150,
                //         "file": "document-73a75f0a-6923-4c59-92fd-432bb3697573",
                //         "created_at": "2024-05-20 21:27:15",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-73a75f0a-6923-4c59-92fd-432bb3697573"
                //     },
                //     {
                //         "id": 162,
                //         "name": "146. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 151,
                //         "file": "document-0c0ac189-4ba3-4331-b4ff-5343310ffbc7",
                //         "created_at": "2024-05-20 21:27:28",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0c0ac189-4ba3-4331-b4ff-5343310ffbc7"
                //     },
                //     {
                //         "id": 163,
                //         "name": "147. MANIFESTAÇÃO GETNET",
                //         "order": 152,
                //         "file": "document-4ca6d700-1500-4ddb-b30a-a2c2c710ee40",
                //         "created_at": "2024-05-21 21:17:39",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4ca6d700-1500-4ddb-b30a-a2c2c710ee40"
                //     },
                //     {
                //         "id": 164,
                //         "name": "148. MANIFESTAÇÃO ONBEHALF FIXAÇÃO DE HONORÁRIOS",
                //         "order": 153,
                //         "file": "document-10a13158-f7d6-4c2e-b14f-eb3409e97f8e",
                //         "created_at": "2024-05-21 21:17:51",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-10a13158-f7d6-4c2e-b14f-eb3409e97f8e"
                //     },
                //     {
                //         "id": 165,
                //         "name": "149. MANIFESTAÇÃO MP",
                //         "order": 154,
                //         "file": "document-79e9ef56-697d-454b-aff6-2d5196518736",
                //         "created_at": "2024-05-23 16:03:45",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-79e9ef56-697d-454b-aff6-2d5196518736"
                //     },
                //     {
                //         "id": 166,
                //         "name": "150. MANIFESTAÇÃO JULIANA E KPMG REL VISITA TÉCNICA",
                //         "order": 155,
                //         "file": "document-223f84cc-37b3-4f2e-8204-39fa9abcbfd7",
                //         "created_at": "2024-05-23 16:04:04",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-223f84cc-37b3-4f2e-8204-39fa9abcbfd7"
                //     },
                //     {
                //         "id": 167,
                //         "name": "151. MANIFESTAÇÃO AGÊNCIA DE TURISMO SAKURA",
                //         "order": 156,
                //         "file": "document-1af1e6a6-1ef4-4ab3-80eb-7eda01ef2f96",
                //         "created_at": "2024-05-27 20:16:32",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1af1e6a6-1ef4-4ab3-80eb-7eda01ef2f96"
                //     },
                //     {
                //         "id": 168,
                //         "name": "152. DESPACHO",
                //         "order": 157,
                //         "file": "document-6b916436-a697-4563-a131-b1d28d42525c",
                //         "created_at": "2024-05-27 20:16:49",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6b916436-a697-4563-a131-b1d28d42525c"
                //     },
                //     {
                //         "id": 169,
                //         "name": "153. MANIFESTAÇÃO CONFIANÇA AGÊNCIA DE PASSAGENS E TURISMO",
                //         "order": 158,
                //         "file": "document-e729467c-9bf0-4310-bf30-3279d4b7bcd9",
                //         "created_at": "2024-06-03 20:05:12",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e729467c-9bf0-4310-bf30-3279d4b7bcd9"
                //     },
                //     {
                //         "id": 170,
                //         "name": "154. MANIFESTAÇÃO BANCO BTG",
                //         "order": 159,
                //         "file": "document-da08287b-24d5-4b0c-8ad5-da41fc2f2bf2",
                //         "created_at": "2024-06-03 20:05:26",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-da08287b-24d5-4b0c-8ad5-da41fc2f2bf2"
                //     },
                //     {
                //         "id": 171,
                //         "name": "155. MANIFESTAÇÃO PROCON SP",
                //         "order": 160,
                //         "file": "document-3e1c7b26-c731-4318-ac2b-9dcba80bdd7d",
                //         "created_at": "2024-06-03 21:45:36",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-3e1c7b26-c731-4318-ac2b-9dcba80bdd7d"
                //     },
                //     {
                //         "id": 172,
                //         "name": "156. MANIFESTAÇÃO MPMG",
                //         "order": 161,
                //         "file": "document-df84aebb-d3f7-4551-bb6f-004c502b44fd",
                //         "created_at": "2024-06-03 21:45:52",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-df84aebb-d3f7-4551-bb6f-004c502b44fd"
                //     },
                //     {
                //         "id": 173,
                //         "name": "157. MANIFESTAÇÃO FLYTOUR AGENCIA DE VIAGENS E TURISMO",
                //         "order": 162,
                //         "file": "document-c7d86366-0b92-4b1f-a973-7658c84c83c4",
                //         "created_at": "2024-06-06 18:31:06",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-c7d86366-0b92-4b1f-a973-7658c84c83c4"
                //     },
                //     {
                //         "id": 174,
                //         "name": "158. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 163,
                //         "file": "document-ab729d7d-325a-476a-a105-1d3bd5841bab",
                //         "created_at": "2024-06-06 18:31:22",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-ab729d7d-325a-476a-a105-1d3bd5841bab"
                //     },
                //     {
                //         "id": 175,
                //         "name": "159. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 164,
                //         "file": "document-0dfa11f3-bb18-4c9a-8bfb-506961a6a742",
                //         "created_at": "2024-06-06 18:31:37",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0dfa11f3-bb18-4c9a-8bfb-506961a6a742"
                //     },
                //     {
                //         "id": 176,
                //         "name": "160. MANIFESTAÇÃO AJ",
                //         "order": 165,
                //         "file": "document-3b68fbfd-9457-499c-a3e9-06d21ef8d796",
                //         "created_at": "2024-06-06 18:31:49",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-3b68fbfd-9457-499c-a3e9-06d21ef8d796"
                //     },
                //     {
                //         "id": 177,
                //         "name": "161. RELATÓRIO GASTOS JULIANA E KPMG",
                //         "order": 166,
                //         "file": "document-66c1f39a-f49d-4b6e-8319-2f5fdf1a8732",
                //         "created_at": "2024-06-06 21:22:20",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-66c1f39a-f49d-4b6e-8319-2f5fdf1a8732"
                //     },
                //     {
                //         "id": 178,
                //         "name": "162. MANIFESTAÇÃO FAZENDA PÚBLICA NACIONAL",
                //         "order": 167,
                //         "file": "document-c174104f-e86a-4bdc-ae53-caa7c4737258",
                //         "created_at": "2024-06-10 21:11:21",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-c174104f-e86a-4bdc-ae53-caa7c4737258"
                //     },
                //     {
                //         "id": 179,
                //         "name": "163. EMBARGOS DE DECLARAÇÃO ONBEHALF AUDITORES",
                //         "order": 168,
                //         "file": "document-da2e9e54-fe7a-4984-8a28-63a50b87e1ae",
                //         "created_at": "2024-06-10 21:11:35",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-da2e9e54-fe7a-4984-8a28-63a50b87e1ae"
                //     },
                //     {
                //         "id": 180,
                //         "name": "164. EMBARGOS DE DECLARAÇÃO FLYTOUR AGENCIA DE VIAGENS E TURISMO",
                //         "order": 169,
                //         "file": "document-7f1e7eb0-0a06-4dcb-903b-62cdeded866c",
                //         "created_at": "2024-06-10 21:11:51",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7f1e7eb0-0a06-4dcb-903b-62cdeded866c"
                //     },
                //     {
                //         "id": 181,
                //         "name": "165. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 170,
                //         "file": "document-cf2c32fe-bcda-42ef-8e9b-9a0dd5261a12",
                //         "created_at": "2024-06-10 21:12:35",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-cf2c32fe-bcda-42ef-8e9b-9a0dd5261a12"
                //     },
                //     {
                //         "id": 182,
                //         "name": "166. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 171,
                //         "file": "document-86cbc67b-1bf1-4282-bcb1-1e0c0975c4ba",
                //         "created_at": "2024-06-10 21:12:47",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-86cbc67b-1bf1-4282-bcb1-1e0c0975c4ba"
                //     },
                //     {
                //         "id": 183,
                //         "name": "167. MANIFESTAÇÃO AJ",
                //         "order": 172,
                //         "file": "document-e10a1880-cf85-49b6-844b-203ed58e2c01",
                //         "created_at": "2024-06-26 16:11:47",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e10a1880-cf85-49b6-844b-203ed58e2c01"
                //     },
                //     {
                //         "id": 184,
                //         "name": "168. MANIFESTAÇÃO BANCO DO BRASIL",
                //         "order": 173,
                //         "file": "document-7d7bdbfb-42ff-42f0-8713-cb239315a9c3",
                //         "created_at": "2024-06-26 16:14:41",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-7d7bdbfb-42ff-42f0-8713-cb239315a9c3"
                //     },
                //     {
                //         "id": 185,
                //         "name": "169. MANIFESTAÇÃO MUNICÍPIO BELO HORIZONTE",
                //         "order": 174,
                //         "file": "document-0f32f5ec-d0a5-4504-ac2c-88de2aef33a9",
                //         "created_at": "2024-06-26 16:15:03",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0f32f5ec-d0a5-4504-ac2c-88de2aef33a9"
                //     },
                //     {
                //         "id": 186,
                //         "name": "170. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 175,
                //         "file": "document-0f633a17-11ab-4df9-91d2-2fd952f26c1c",
                //         "created_at": "2024-06-26 16:15:18",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0f633a17-11ab-4df9-91d2-2fd952f26c1c"
                //     },
                //     {
                //         "id": 187,
                //         "name": "171. RMA - JAN. A MAR.2024",
                //         "order": 176,
                //         "file": "document-3117a851-1d33-4d6c-96ce-e195093a9aaf",
                //         "created_at": "2024-06-26 16:15:32",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-3117a851-1d33-4d6c-96ce-e195093a9aaf"
                //     },
                //     {
                //         "id": 188,
                //         "name": "172. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 177,
                //         "file": "document-b7566a5e-ba19-4672-b5bd-449be995db8f",
                //         "created_at": "2024-06-26 16:15:44",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-b7566a5e-ba19-4672-b5bd-449be995db8f"
                //     },
                //     {
                //         "id": 189,
                //         "name": "173. MANIFESTAÇÃO AJ",
                //         "order": 178,
                //         "file": "document-e6527d0f-246c-4dc3-b6a0-cd79f5b3a40d",
                //         "created_at": "2024-06-26 16:15:58",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e6527d0f-246c-4dc3-b6a0-cd79f5b3a40d"
                //     },
                //     {
                //         "id": 190,
                //         "name": "174. MANIFESTAÇÃO BRIZOLA E INOCÊNCIO",
                //         "order": 179,
                //         "file": "document-0626cf6e-df5f-4023-97df-594d5485b82b",
                //         "created_at": "2024-06-26 16:16:18",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0626cf6e-df5f-4023-97df-594d5485b82b"
                //     },
                //     {
                //         "id": 191,
                //         "name": "175. MANIFESTAÇÃO MPMG",
                //         "order": 180,
                //         "file": "document-e1e4b645-309f-4c84-86af-5ee75325991a",
                //         "created_at": "2024-07-01 16:49:03",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e1e4b645-309f-4c84-86af-5ee75325991a"
                //     },
                //     {
                //         "id": 192,
                //         "name": "176. MANIFESTAÇÃO JULIANA E KPMG",
                //         "order": 181,
                //         "file": "document-4fd708ab-b0fa-4000-9415-3e79a68175a7",
                //         "created_at": "2024-07-03 21:24:51",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-4fd708ab-b0fa-4000-9415-3e79a68175a7"
                //     },
                //     {
                //         "id": 193,
                //         "name": "177. MANIFESTAÇÃO GOL",
                //         "order": 182,
                //         "file": "document-87043c5c-61b1-4c06-a816-6fadc6dd096d",
                //         "created_at": "2024-07-03 21:25:04",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-87043c5c-61b1-4c06-a816-6fadc6dd096d"
                //     },
                //     {
                //         "id": 194,
                //         "name": "178. MANIFESTAÇÃO BANCO SANTANDER",
                //         "order": 183,
                //         "file": "document-825cd0c1-6f0d-4186-a505-2db3d0ba5438",
                //         "created_at": "2024-07-03 21:25:17",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-825cd0c1-6f0d-4186-a505-2db3d0ba5438"
                //     },
                //     {
                //         "id": 195,
                //         "name": "179. MANIFESTAÇÃO ABIPAG",
                //         "order": 184,
                //         "file": "document-9e974840-f0ae-4c16-95ef-5269ed3a22cb",
                //         "created_at": "2024-07-09 21:31:24",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-9e974840-f0ae-4c16-95ef-5269ed3a22cb"
                //     },
                //     {
                //         "id": 196,
                //         "name": "180. DECISÃO",
                //         "order": 185,
                //         "file": "document-e3a5410b-6b8b-46e3-969c-60b2300ec376",
                //         "created_at": "2024-07-11 17:31:41",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-e3a5410b-6b8b-46e3-969c-60b2300ec376"
                //     },
                //     {
                //         "id": 197,
                //         "name": "181. RELATÓRIO GASTOS JULIANA E KPMG",
                //         "order": 186,
                //         "file": "document-6a49f077-e788-49ae-9752-79f1f373e9ad",
                //         "created_at": "2024-07-11 17:32:00",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6a49f077-e788-49ae-9752-79f1f373e9ad"
                //     },
                //     {
                //         "id": 198,
                //         "name": "182. MANIFESTAÇÃO ITAÚ UNIBANCO",
                //         "order": 187,
                //         "file": "document-fbf912f4-00ea-45c1-9ebc-4e7883cfcb85",
                //         "created_at": "2024-07-17 16:41:24",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-fbf912f4-00ea-45c1-9ebc-4e7883cfcb85"
                //     },
                //     {
                //         "id": 199,
                //         "name": "183. CIÊNCIA MP",
                //         "order": 188,
                //         "file": "document-5fbd1419-76ff-4569-80a3-e5f7519de08e",
                //         "created_at": "2024-07-17 16:41:40",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5fbd1419-76ff-4569-80a3-e5f7519de08e"
                //     },
                //     {
                //         "id": 200,
                //         "name": "184. MANIFESTAÇÃO UNIÃO",
                //         "order": 189,
                //         "file": "document-8eb94bb5-b7d0-497f-baac-d6c73033b5ad",
                //         "created_at": "2024-07-17 16:41:55",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-8eb94bb5-b7d0-497f-baac-d6c73033b5ad"
                //     },
                //     {
                //         "id": 201,
                //         "name": "185. RELATÓRIO DE VISITA TÉCNICA JULIANA E KPMG",
                //         "order": 190,
                //         "file": "document-27f1288c-7dbc-48a8-8a96-7272c9734ce0",
                //         "created_at": "2024-07-17 21:24:47",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-27f1288c-7dbc-48a8-8a96-7272c9734ce0"
                //     },
                //     {
                //         "id": 202,
                //         "name": "186. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 191,
                //         "file": "document-6abade91-a96b-4ff0-8a32-914dca4f9403",
                //         "created_at": "2024-07-17 21:25:01",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-6abade91-a96b-4ff0-8a32-914dca4f9403"
                //     },
                //     {
                //         "id": 203,
                //         "name": "187. ATA AUDIÊNCIA ADMINISTRATIVA 17.07",
                //         "order": 192,
                //         "file": "document-961dfafa-601e-4647-817e-3469b5c5b933",
                //         "created_at": "2024-07-17 21:25:18",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-961dfafa-601e-4647-817e-3469b5c5b933"
                //     },
                //     {
                //         "id": 205,
                //         "name": "188. RMA - ABR. E MAI.2024",
                //         "order": 193,
                //         "file": "document-84a6b56e-905e-4cf8-b317-0487bdb905d0",
                //         "created_at": "2024-07-26 18:35:28",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-84a6b56e-905e-4cf8-b317-0487bdb905d0"
                //     },
                //     {
                //         "id": 206,
                //         "name": "189. MANIFESTAÇÃO MERCADO PAGO",
                //         "order": 194,
                //         "file": "document-5d9e1922-bac6-4b2f-9dcc-1a26a0af0d1d",
                //         "created_at": "2024-08-01 22:23:36",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-5d9e1922-bac6-4b2f-9dcc-1a26a0af0d1d"
                //     },
                //     {
                //         "id": 207,
                //         "name": "190. AGRAVO RECUPERANDAS",
                //         "order": 195,
                //         "file": "document-9e7cbf5c-dc73-4f01-95b2-525fe2afa67b",
                //         "created_at": "2024-08-06 21:29:51",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-9e7cbf5c-dc73-4f01-95b2-525fe2afa67b"
                //     },
                //     {
                //         "id": 208,
                //         "name": "191. CIÊNCIA AJ",
                //         "order": 196,
                //         "file": "document-1628253a-7540-4833-ac8b-15d785e82210",
                //         "created_at": "2024-08-13 21:15:00",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-1628253a-7540-4833-ac8b-15d785e82210"
                //     },
                //     {
                //         "id": 209,
                //         "name": "192. MANIFESTAÇÃO GETNET",
                //         "order": 197,
                //         "file": "document-df747661-a73c-4173-911f-562fda0baa82",
                //         "created_at": "2024-08-13 21:15:12",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-df747661-a73c-4173-911f-562fda0baa82"
                //     },
                //     {
                //         "id": 210,
                //         "name": "193. MANIFESTAÇÃO RECUPERANDAS",
                //         "order": 198,
                //         "file": "document-0c45ca88-c9ed-4334-bb37-7a9d2361f8e7",
                //         "created_at": "2024-08-19 21:30:11",
                //         "updated_at": "2024-08-19 21:30:23",
                //         "file_url": "https://rj123milhas.nyc3.digitaloceanspaces.com/document-0c45ca88-c9ed-4334-bb37-7a9d2361f8e7"
                //     }
                // ]

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },


        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'main-documents-description' }
                })

                this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.header-text {
    color: white !important;
    background-color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
}

.document-card-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 18px;
    line-height: 0px;
}

.document-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 18px;
    line-height: 0px;
}

.custom-panel {
    margin-bottom: 15px;
}
</style>
