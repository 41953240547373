<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Pedido de habilitação/divergência de crédito</div>
            </v-col>
        </v-row>

        <br />

        <div class="mt-4" v-if="dataset.loading">
            <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </div>

        <div v-html="!user ? dataset.contentUserAnonymous : dataset.contentUserLogged"></div>

        <v-form v-if="user" class="pt-10" ref="form" v-model="dataForm.validForm">

            <!-- <v-row>
                <v-col cols="12">
                    <span class="mr-3"> <strong>Empresa relacionada a esta divergência de crédito</strong></span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon small :color="'primary'" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                        </template>
                        <span>Selecione a empresa a qual gostaria de habilitar ou divergir de um crédito</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="mt-n3">
                    <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.clients" label="Devedora"
                        v-model="dataset.data.client" item-value="id" item-text="name" :rules="[$rules.required]"
                        outlined hide-details="auto" no-data-text="Nenhuma devedora encontrada">
                    </v-autocomplete>
                </v-col>
            </v-row> -->

            <template >
                <v-row class="justify-start">
                    <v-col cols="12">
                        <span class="mr-3"> <strong>Possui ação judicial em andamento ou com trânsito em julgado certificado?</strong></span>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small :color="'primary'" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                            </template>
                            <span>Caso possua uma ação judicial em andamento, marque "SIM" e preencha os dados abaixo</span>
                        </v-tooltip>
                    </v-col>

                    <v-col cols="12" class="mt-n7">
                        <v-radio-group v-model="dataset.data.actionProgress" @change="onRadioChange">
                            <v-radio label="Sim" :value="true"></v-radio>
                            <v-radio label="Não" :value="false"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </template>

                <template v-if="dataset.data.actionProgress === true ">
                    <div v-for="(process, index) in processes" :key="process.id || `process-${index}`">    
                        <v-row class="mt-n5">
                        <v-col cols="5" sm="5">
                          <v-text-field 
                            :disabled="dataset.loading" 
                            dense 
                            required 
                            label="Número do processo"
                            :rules="[$rules.required]" 
                            v-model="process.number" 
                            outlined 
                            hide-details="auto">
                          </v-text-field>
                        </v-col>
                  
                        <v-col cols="3" sm="3">
                          <v-autocomplete 
                            :disabled="dataset.loading" 
                            dense 
                            required 
                            :items="dataset.statesList"
                            label="Local de tramitação" 
                            v-model="process.local" 
                            item-value="name"
                            item-text="name" 
                            :rules="[$rules.required]" 
                            outlined 
                            hide-details="auto">
                          </v-autocomplete>
                        </v-col>
                  
                        <v-col cols="4" sm="4">
                          <v-autocomplete 
                            :disabled="dataset.loading" 
                            dense 
                            :items="dataset.courtList"
                            label="Tribunal de tramitação" 
                            v-model="process.court"
                            item-value="name" 
                            item-text="name" 
                            outlined 
                            hide-details="auto">
                          </v-autocomplete>
                        </v-col>
                  
                        <v-col cols="12" sm="12" class="d-flex justify-end mt-n2">
                                                    
                            <!-- Remove Button -->  
                            <v-tooltip v-if="processes.length > 1" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" @click="removeProcess(index)" color="#E57373" dark x-small >
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remover linha de dados</span>
                            </v-tooltip>

                            <!-- ADD Button --> 
                            <v-tooltip v-if="processes.length < 3 && index === processes.length - 1" bottom>
                                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                <v-btn class='ml-2' v-bind="tooltipAttrs" v-on="tooltipOn" @click="addProcess" color="green" dark x-small>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                </template>
                                <span>Adicionar linha de dados</span>
                            </v-tooltip>  
                        </v-col>
                      </v-row>
                      <br>
                    </div>
                </template>

                <v-divider></v-divider>

                <br>
                <v-row>
                    <v-col>
                        <div class="d-flex align-center">
                            <h3 ><strong>Negócios</strong></h3>
                        
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                                <v-btn class='ml-8' v-bind="tooltipAttrs" v-on="tooltipOn" @click="addItem" color="green" dark x-small>
                                    Adicionar <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                </template>
                                <span>Adicionar um novo negócio</span>
                            </v-tooltip>
                        </div> 
                    </v-col>
                </v-row>

           
            <div v-for="(item, i) in dataset.data.items" :key="i">

                <br>
                <v-row>
                    <v-col>
                        <strong>Negócio #{{ getItemNumber(i) }}</strong>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class='ml-8' v-bind="attrs" v-on="on" @click="removeItem(i)" dark color="#E57373" x-small >
                                    Remover <v-icon>mdi-minus</v-icon>
                                </v-btn>
                            </template>
                            <span>Excluir negócio atual</span>
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-card>

                                    <v-expansion-panel-header>

                                        <div>

                                            <strong>Origem: </strong>
                                            <span>{{ truncateText(item.origin || 'N/A', 30) }}</span>
                                            /
                                            <strong>Valor: </strong>
                                            <span v-if="item.amount">

                                                <!-- {{ item.amount | currency }}</span><span v-else>N/A</span> -->
                                                {{ formatValue(item.amount, item.currency) }}</span><span v-else>N/A</span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <br>
                                        <v-row>

                                            <v-col cols="12">
                                                <span class="mr-3"> <strong>Selecione a origem do
                                                        crédito</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Selecione o motivo que gerou os valores do crédito</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-autocomplete :disabled="dataset.loading" dense required
                                                    :items="dataset.creditorOriginTypes"
                                                    label="Selecione a origem do crédito" v-model="item.origin"
                                                    item-value="name" item-text="name" :rules="[$rules.required]"
                                                    outlined hide-details="auto" @change="onOriginChange(item, i)">
                                                </v-autocomplete>
                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <span class="mr-3"> <strong>Informe a moeda e o valor referente ao
                                                        negócio</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Informe o tipo de moeda e o valor devido</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-select :disabled="dataset.loading" dense id="type"
                                                    :items="dataset.currencyTypes" label="Selecione uma moeda"
                                                    v-model="item.currency" item-value="id" item-text="name" outlined
                                                    hide-details="auto">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-currency-field :disabled="dataset.loading" label="Valor" dense
                                                    v-model="item.amount" :allowNegative="false" hide-details="auto"
                                                    outlined required :rules="[$rules.required]">
                                                </v-currency-field>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12">
                                                <span class="mr-3"> <strong>Selecione a empresa devedora</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Selecione a empresa devedora do crédito</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
    
                                        <v-row>
                                            <v-col cols="12" sm="12">
                                                <v-autocomplete :disabled="dataset.loading" dense required :items="dataset.clients"
                                                    label="Selecione a devedora" v-model="item.company_name" item-value="name" item-text="name"
                                                    outlined hide-details="auto">
                                                </v-autocomplete>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12">
                                                <span class="mr-3"> <strong>Número de identificação do
                                                        pedido/cotação/contrato/outro</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Informe o número de identificação do pedido</span>
                                                </v-tooltip>

                                            </v-col>

                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">
                                                <v-text-field :disabled="dataset.loading" dense
                                                    label="Identificação do pedido" v-model="item.request_number"
                                                    outlined hide-details="auto">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        
                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <span class="mr-3"> <strong>Informe a sua classe no
                                                        processo</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Selecione a sua classe no processo</span>
                                                </v-tooltip>
                                            </v-col>
                                        
                                            <v-col cols="12" sm="6">
                                                <span class="mr-3" > 
                                                    <strong>
                                                        {{ 
                                                            item.textDateLabel === 2 ? 'Data de rescisão do contrato de trabalho' : 
                                                            item.textDateLabel === 1 ? 'Data da compra / assinatura do contrato' : 
                                                            'Data do evento'
                                                        }}
                                                    </strong>
                                                </span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>
                                                        {{ 
                                                            item.textDateLabel === 2 ? 'Informe a data de rescisão do contrato de trabalho' : 
                                                            item.textDateLabel === 1 ? 'Informe a data da compra / assinatura do contrato' : 
                                                            'Data do evento'
                                                        }}
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row> 

                                        <v-row>
                                            <v-col cols="12" sm="6">
                                                <v-select dense :items="dataset.creditorTypes" label="Classe"
                                                    v-model="item.type" item-value="id" item-text="name" outlined
                                                    hide-details="auto" :rules="[$rules.required]" @change="onClassChange(item, i)">
                                                </v-select>
                                            </v-col>
                                        
                                            <v-col cols="12" sm="6">
                                                <v-text-field dense type="date" label="Data"
                                                    :value="item.ref_date ? $moment(item.ref_date).utc().format('YYYY-MM-DD') : null"
                                                    outlined hide-details="auto" @input="updateRefDate($event, i)">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>

                                        <!-- <v-row>
                                            <v-col cols="12">
                                                <span class="mr-3"> <strong>Informe a sua classe no
                                                        processo</strong></span>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-icon small :color="'primary'" v-bind="attrs"
                                                            v-on="on">mdi-help-circle</v-icon>
                                                    </template>
                                                    <span>Selecione a sua classe no processo</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12">

                                                <v-select dense :items="dataset.creditorTypes" label="Classe"
                                                    v-model="dataset.data.type" item-value="id" item-text="name"
                                                    outlined hide-details="auto">
                                                </v-select>
                                            </v-col>
                                        </v-row> -->

                                        <!-- DOCS COMPROBATÓRIOS -->

                                        <v-row>
                                            <v-col cols="12" lg="12">

                                                <v-row>
                                                    <v-col cols="12">
                                                        <span class="mr-3"> <strong>Documento(s)
                                                                Comprobatório(s)</strong></span>
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-icon small :color="'primary'" v-bind="attrs"
                                                                    v-on="on">mdi-help-circle</v-icon>
                                                            </template>
                                                            <span>Envie o(s) documento(s) que comprovem o negócio</span>
                                                        </v-tooltip>
                                                    </v-col>
                                                </v-row>

                                                <v-row>

                                                    <v-col cols='12'>

                                                        <v-text-field :disabled="dataset.loading" readonly
                                                            hide-details="auto" prepend-icon="mdi-paperclip"
                                                            :clearable="true" @click:clear="clearDocs(i, 3)"
                                                            label="Documentos Comprobatórios"
                                                            @click.prevent="openDocumentModal(i, 3)" outlined
                                                            class="mt-1" dense :value="getDocsByType(i, 3)"
                                                            :rules="[$rules.required]" />

                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col v-for="item in getDocsURLByType(i, 3)" :key="item.id + 'doc3'"
                                                        cols="auto">
                                                        <v-card>
                                                            <v-img @click="openDoc(item.file_url)" class="rounded-xl"
                                                                lazy-src="https://picsum.photos/id/11/10/6" :height="70"
                                                                :width="70" :src="item.file_url"></v-img>
                                                            <v-card-text class="pa-0 file-text"> {{ item.name }}
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>

                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col cols="12">
                                                <p>
                                                    <strong>Informe uma observação referente ao negócio</strong>
                                                </p>
                                                <v-textarea :disabled="dataset.loading" dense id="description"
                                                    label="Informações Adicionais"
                                                    :rules="[$rules.maxlength(item.observation, 500)]" counter="500"
                                                    v-model="item.observation" outlined hide-details="auto" rows="2"
                                                    auto-grow>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>

                                        <!-- <v-row class=mt-1>
                                            <v-col>
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn v-bind="attrs" v-on="on" color="primary" text
                                                            :loading="dataset.loading" @click.native="removeItem(i)">
                                                            <v-icon> mdi-delete-outline </v-icon>
                                                            Excluir
                                                        </v-btn>
                                                    </template>
                                                    <span>Exclui o negócio atual</span>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row> -->

                                    </v-expansion-panel-content>
                                </v-card>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

            </div>
            <br>
            <v-row dense>
                <v-col>
                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn v-on="on" color="primary" :loading="dataset.loading"
                                @click.native="save">Salvar</v-btn>
                        </template>
                        <span>Salvar alterações</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                        <template #activator="{ on }">
                            <v-btn class="ml-4" v-on="on" type="button" elevation="0" color="primary" outlined
                                to="/habilitacoes-divergencias">Voltar</v-btn>
                        </template>
                        <span>Retornar para habilitações e divergências</span>
                    </v-tooltip>
                </v-col>
            </v-row>

        </v-form>

        <!-- MODAIS -->

        <v-dialog v-model="showDocumentModal" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Escolha um
                                <span class="underline text-uppercase primary--text">documento</span>
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-card-text>
                    <v-btn class='mb-3' @click="openAddModalDocument(currentDocTypeId)" color="primary">
                        Novo documento
                    </v-btn>
                    <v-expansion-panels v-model="panel" multiple>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Selecionar da Central de Documentos</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-list dense v-if="!loadingDocuments">
                                    <v-list-item v-for="(doc, index) in documentsCenter" :key="index">
                                        <v-list-item-action>

                                            <v-checkbox v-model="doc.isSelected" :disabled="currentDocType !== 3 && currentDocType !== 4 &&
                                                !doc.isSelected && isAnyDocumentSelected">
                                            </v-checkbox>

                                        </v-list-item-action>
                                        <v-list-item-content>{{ doc.name }}</v-list-item-content>
                                        <v-list-item-content>{{ getDocTypeName(doc.type) }}</v-list-item-content>
                                    </v-list-item>
                                </v-list>
                                <v-progress-circular v-else indeterminate></v-progress-circular>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>

                    <v-card-actions class='pa-0 mt-4'>
                        <v-btn color="primary" @click="confirmSelection">Confirmar</v-btn>
                        <v-btn color="error" @click="checkDocumentsBeforeClose">Fechar</v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showAddDocumentModal" max-width="800px">
            <v-card>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Adicionar um
                                <span class="underline text-uppercase primary--text">documento</span>
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>

                <v-form ref="formNewDocument" v-model="valid">

                    <v-card-text class='mt-1'>
                        <v-text-field outlined dense readonly :value="getDocTypeName(currentDocTypeId)"
                            label="Tipo de Documento"></v-text-field>

                        <v-text-field outlined dense required :rules="[$rules.required]" v-model="newDocument.name"
                            label="Nome"></v-text-field>

                        <v-file-input outlined dense required prepend-icon="mdi-paperclip" :rules="[$rules.required]"
                            v-model='newDocument.file' label="Upload do Documento">
                        </v-file-input>
                    </v-card-text>


                    <v-col cols='12' sm='3'>
                        <v-btn @click="saveNewDocument" color="primary" block :disabled="dataset.loading">
                            Enviar
                            <v-progress-circular v-if="dataset.loading" indeterminate color="white" size="20"
                                class="ml-2"></v-progress-circular>
                        </v-btn>
                    </v-col>


                </v-form>
            </v-card>
        </v-dialog>

        <v-dialog max-width="800px" v-model="showConfirmationDialog">
            <v-card>
                <v-card-title>Confirmação</v-card-title>
                <v-card-text>Você possui documento(s) selecionado(s) que não foram salvos. Deseja mesmo sair sem salvar
                    suas
                    alterações?</v-card-text>
                <v-card-actions>
                    <v-btn text @click="continueWithoutSaving">Não</v-btn>
                    <v-btn text @click="closeAlertModal">Sim</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- MODAIS -->

        <!-- <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row> 
    </section>
-->
    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from '@/main';

import currencyTypes from '@/common/data/currency-types.data';
//import topButton from '@/components/layout/GoToTopButton.vue';
//import whatsButton from '@/components/layout/WhatsAppButton.vue';
import itemTypes from "@/common/data/item-types.data.js";
import creditorTypes from "@/common/data/creditor-types.data.js";
import creditorOriginTypes from "@/common/data/creditor-origin-types.data.js";
import statesList from "@/common/data/states-list.data";
import courtList from "@/common/data/court-types.data"


import { v4 as uuidv4 } from 'uuid';

import axios from 'axios';

export default {

    // components: {
    //     topButton,
    //     whatsButton
    // },

    data: () => ({
        user: null,
        valid: true,
        panel: [0],
        showConfirmationDialog: false,
        loadingDocuments: false,
        currentDocTypeName: '',
        dataset: {
            currencyTypes: currencyTypes,
            contentUserAnonymous: '',
            contentUserLogged: '',
            clients: [],
            selectedEventItem: -1,
           // textDateLabel: null,
            data: {
                items: [],
                actionProgress: false
            },
            loading: false,
            itemTypes: itemTypes,
            creditorTypes: creditorTypes,
            creditorOriginTypes: creditorOriginTypes,
            statesList: statesList,
            courtList: courtList,
            // hasDocumentType3: false,

        },
        dataForm: {
            validForm: true,
            addLoading: false,
            updateLoading: false,
            removeLoading: false,
            created: null
        },
        showDocumentModal: false,
        showAddDocumentModal: false,
        documentsCenter: [],
        newDocument: {},
        selectedPetitionDocument: null,
        selectedProcurationDocument: null,
        selectedSpreadsheet: null,
        selectSuportingDocument: [],
        selectOtherDocuments: [],
        currentDocTypeId: null,
        processes: [],
    }),

    mounted: function () {

    },


    computed: {
        isAnyDocumentSelected() {
            if (this.currentDocType === 'idOtherDocuments') {
                return false;
            }
            return this.documentsCenter.some(doc => doc.isSelected);
        },
    },

    watch: {
        dataset: {
            handler(newVal) {
                newVal.data.items.forEach((item, index) => {
                    this.$watch(() => item.origin, (newValue) => {
                        this.onOriginChange(item, index);
                    });
                });
            },
            deep: true
        }
    },


    created() {
        if (this.$root.user) {
            this.loadUserInfo();
            this.loadClients();
            this.load();
        }

        this.loadText();

        if (this.$route.query.token) {
            this.verifyAccount(this.$route.query.token);
        }
    },

    methods: {

        clearDocs(index, type) {

            const filteredDocs = this.dataset.data.items[index].docs.filter((x) => x.type != type)

            // this.dataset.data.items[index].docs = this.dataset.data.items[index].docs.filter(doc => doc.type !== type);]

            this.dataset.data.items[index].docs = filteredDocs

            return true;
        },

        getDocsByType(index, type) {

            const result = this.dataset.data.items[index].docs.filter((x) => x.type == type).map((x) => x.name).join("; ");

            return result;
        },

        getDocsURLByType(index, type) {
            const result = this.dataset.data.items[index].docs.filter((x) => x.type == type);

            return result;
        },

        getDocTypeName(docTypeId) {
            let docType = this.dataset.itemTypes.find(type => type.id === docTypeId);
            return docType ? docType.name : 'Tipo Desconhecido';
        },

        addProcess() {
            if (this.processes.length < 3) {
                this.processes.push({
                    number: '',
                    local: '',
                    court: '',
                });
            }
        },
   
        removeProcess(index) {
            if (this.processes.length > 1) {
                this.processes.splice(index, 1);
            }
        },

        onRadioChange(value) {
            if (value === true) {
                this.addProcess(); 
            } else if (value === false) {
                this.processes = [];
            }
        },

        onOriginChange(item, index) {

            if (!item) {
                
                return;
            }

            const selectedOrigin = this.dataset.creditorOriginTypes.find(o => o.name === item.origin);

            if (selectedOrigin) {
                if ([1, 2, 3, 4, 5].includes(selectedOrigin.id)) {

                this.$set(this.dataset.data.items, index, {
                        ...this.dataset.data.items[index],
                        type: 3,
                        textDateLabel: 1
                    });
                    
                }
                else{
                    this.$set(this.dataset.data.items, index, {
                        ...this.dataset.data.items[index],
                        type: 1,
                         textDateLabel: 2
                    });
                   
                }
            }
        },

        onClassChange(item, index) {

            if (!item) {
                
                return;
            }
           
            if (item.type===1) {
                  
                this.$set(this.dataset.data.items, index, {
                    ...this.dataset.data.items[index],
                    textDateLabel: 2
                });
                        
            }else if (item.type === 2 || item.type === 3 || item.type === 4) {
                this.$set(this.dataset.data.items, index, {
                    ...this.dataset.data.items[index],
                    textDateLabel: 1
                });
                
            }
        },

        updateRefDate(value, index) {

            this.$set(this.dataset.data.items, index, {
                ...this.dataset.data.items[index],
                ref_date: value
            });
        },

        formatValue(value, currency) {
            if (value === null || value === undefined) {
                return 'N/A';
            }

            let options = {
                style: 'currency',
                currency: 'BRL'
            };

            switch (currency) {
                case 1:
                    options.currency = 'BRL';
                    break;
                case 2:
                    options.currency = 'USD';
                    break;
                case 3:
                    options.currency = 'EUR';
                    break;
                default:
                    options.currency = 'BRL';
            }

            return new Intl.NumberFormat('pt-BR', options).format(value);
        },


        selectCreditorTypes() {

            const client_id = this.dataset.data.client

            let client = this.dataset.clients.find(item => item.id == client_id);

            if (client.is_event_opened === true) {
                return this.dataset.creditorTypes;
            } else {
                return this.dataset.creditorTypes.filter(type => type.id === 1);
            }
        },

        checkDocumentsBeforeClose() {
            if (this.documentsCenter.some(doc => doc.isSelected)) {
                this.showConfirmationDialog = true;
            } else {
                this.closeModal();
            }
        },

        closeAlertModal() {
            this.showConfirmationDialog = false;
            this.closeModal();
        },

        continueWithoutSaving() {
            this.showConfirmationDialog = false;
        },

        truncateText(text, maxLength) {
            return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
        },

        hasDocument(index, type, required) {
            if (!required) return true;

            const items = this.dataset.data.items[index].files.filter((x) => x.type == type);

            return items.length > 0;
        },


        onCancel(index, step) {
            if (step > 1) {
                this.dataset.data.items[index].step = step - 1;
            }

            switch (step) {
                case 2:
                    this.selectedPetitionDocument = null;
                    this.selectedProcurationDocument = null;
                    this.dataset.data.items.file1 = null;
                    this.dataset.data.items.file2 = null;


                    break;
                case 3:
                    this.selectedProcurationDocument = null;
                    this.selectedSpreadsheet = null;
                    this.dataset.data.items.file2 = null;
                    this.dataset.data.items.file3 = null;
                    break;
                case 4:
                    this.selectedSpreadsheet = null;
                    this.selectSuportingDocument = [];
                    this.dataset.data.items.file3 = null;
                    this.dataset.data.items.file4 = [];

                    break;
                case 5:
                    this.selectSuportingDocument = [];
                    this.selectOtherDocuments = [];
                    this.dataset.data.items.file4 = [];
                    this.dataset.data.items.file5 = [];

                    break;
            }

        },

        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'create-user-event-anonymous,create-user-event-logged' }
                });

                if (!result.data.content) return;

                let contentUserAnonymous = result.data.content.find(
                    (x) => x.key === 'create-user-event-anonymous'
                );
                let contentUserLogged = result.data.content.find(
                    (x) => x.key === 'create-user-event-logged'
                );

                this.dataset.contentUserAnonymous = contentUserAnonymous
                    ? contentUserAnonymous.value
                    : '';
                this.dataset.contentUserLogged = contentUserLogged ? contentUserLogged.value : '';
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        getItemNumber(i) {
            return i + 1;
        },

        async loadClients() {
            try {
                const result = await axios.get(`/api/clients`, { params: { limit: 100000 } });

                if (!result.data.content.data) return;

                this.dataset.clients = result.data.content.data;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                });
            }
        },

        async loadUserInfo() {
            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/users/info');

                this.user = result.data.content;
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },

        async load() {
            this.dataset.loading = true;

            try {
                const result = await axios.get(`/api/user-events/request/${this.$route.params.id}`)

                this.dataset.data = result.data.content;

                if (this.dataset.data.processes.length > 0) {
                    this.dataset.data.actionProgress = true;
                }else{
                    this.dataset.data.actionProgress = false; 
                }
                    
                this.processes = []

                if (Array.isArray(this.dataset.data.processes) && this.dataset.data.processes.length > 0) {
           
                    for (let i = 0; i < this.dataset.data.processes.length; i++) {
                        const process = this.dataset.data.processes[i];

                        this.processes.push(process);
                    }
                }

                let items = [];

                for (let item of this.dataset.data.items) {
                    let docs = [];

                    for (let docItem of item.docs) {
                        let data = {
                            name: docItem.doc.name,
                            type: docItem.doc.type,
                            created_at: docItem.doc.created_at,
                            file_url: docItem.doc.file_url,
                            id: docItem.doc.id
                        };

                        docs.push(data);
                    }

                    item.docs = docs;

                    items.push(item);
                }

                this.dataset.data = { ...this.dataset.data, items };

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 5000
                });
            } finally {
                this.dataset.loading = false;
            }
        },


        validateFormItems() {
            if (!this.dataset.data.items.length) return false;

            for (let item of this.dataset.data.items) {
                if (!item.origin || !item.amount || !item.currency) {
                    return false;
                }

                if (!item.type || (Array.isArray(item.file4) && item.file4.length === 0)) {
                    return false;
                }
            }
            return true;
        },


        async save() {

            // if (!this.$refs.form.validate()) {

            //     this.$dialog.notify.error(
            //         'Preencha as informações obrigatórias de um negócio antes de continuar',
            //         {
            //             position: 'top-right',
            //             timeout: 2000
            //         }
            //     );
            //     return;
            // }

            if (!this.$refs.form.validate()) {

                this.$dialog.notify.error(
                    'Preencha as informações obrigatórias de um negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }

            if (!this.dataset.data.items.length) {
                this.$dialog.notify.error(
                    'Preencha as informações do negócio antes de continuar',
                    {
                        position: 'top-right',
                        timeout: 2000
                    }
                );
                return;
            }

            if (!this.validateFormItems()) {
                this.$dialog.notify.error('Preencha todos os campos antes de continuar', {
                    position: 'top-right',
                    timeout: 2000
                });
                return;
            }

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar o formulário?',
                title: 'Atualizar',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            });

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            //console.log("payload: ", payload)

            try {

                const response = await axios.patch(`/api/user-events/request/${this.dataset.data.id}`, payload);

                await this.$dialog.confirm({
                    //text: 'Informações enviadas com sucesso! Você receberá um e-mail assim que sua solicitação for analisada.',
                    text: 'Seu pedido de habilitação/divergência (alteração da lista de pessoas com valores a receber) foi enviado! Os Administradores Judiciais nomeados irão analisar a documentação e os dados incluídos. O resultado será registrado neste site e enviado também pelo e-mail credito@rj123milhas.com.br. Continue acompanhando este site para informações oficiais e atualizadas sobre a Recuperação Judicial do Grupo 123 Milhas.',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                });

                this.dataset.data = { items: [] };

                this.$router.replace('/habilitacoes-divergencias');
            } catch (error) {
                console.log(error)
            } finally {
                this.dataset.loading = false;
            }
        },


        buildPayload() {
            let payload = {
                client_id: 1, //FIXADO ID CASO (123MILHAS) this.dataset.data.client.id,
                // process_number: this.dataset.data.actionProgress ? this.dataset.data.process_number : "",
                // process_local: this.dataset.data.actionProgress ? this.dataset.data.process_local : "",
                // process_court: this.dataset.data.actionProgress ? this.dataset.data.process_court : "",
                processes: this.dataset.data.actionProgress ? this.processes : [],
                items: []
            };

            for (let item of this.dataset.data.items) {

                let itemData = {
                    id: item.id,
                    currency: item.currency,
                    origin: item.origin,
                    amount: item.amount,
                    observation: item.observation,
                    type: item.type,
                    request_number: item.request_number,
                    company_name: item.company_name,
                    ref_date: item.ref_date,
                    files: []
                };

                for (let file of item.docs) {
                    itemData.files.push(file.id);
                }

                payload.items.push(itemData);
            }

            return payload;
        }
        ,

        addItem() {
            this.dataset.data.items.push({
                currency: 1,
                step: 1,
                docs: []
            });
        },

        async removeItem(index) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja <b>remover</b> o negócio atual?',
                title: 'Remover negócio',
                actions: {
                    false: 'Cancelar',
                    true: 'Remover'
                }
            });

            if (!proceedAction) return;

            this.dataset.data.items.splice(index, 1);
        },


        // UP DOC

        confirmSelection() {
            const selectedDocs = this.documentsCenter.filter(doc => doc.isSelected);

            const existingDocs = this.dataset.data.items[this.dataset.selectedEventItem].docs;
            const existingDocNames = existingDocs.map(doc => doc.name);

            const newDocs = selectedDocs.filter(doc => !existingDocNames.includes(doc.name));

            if (newDocs.length < selectedDocs.length) {

                this.$dialog.notify.error("Já existe um arquivo com este nome! Por favor selecione outro arquivo!", { position: 'top-right', timeout: 5000 });

                return;
            }

            this.dataset.data.items[this.dataset.selectedEventItem].docs = existingDocs.concat(newDocs);

            this.closeModal();
        },


        closeModal() {
            this.showDocumentModal = false;
            this.newFile = null;
            this.documentsCenter.forEach(doc => doc.selected = false);
        },

        closeAddModalDocument() {
            this.showAddDocumentModal = false;
        },

        openAddModalDocument(docTypeId) {
            this.currentDocTypeId = docTypeId;
            this.currentDocTypeName = this.getDocTypeName(docTypeId);
            this.showAddDocumentModal = true;
        },


        openDocumentModal(index, type) {
            this.currentDocType = type;

            this.currentDocTypeId = type;

            this.dataset.selectedEventItem = index;

            this.loadingDocuments = true;

            this.getDocuments(type).finally(() => {
                this.loadingDocuments = false;
                this.showDocumentModal = true;
            });
        },


        openDoc(doc) {
            window.open(doc, '_blank');
            // this.selectedDoc = doc;
            // this.dialogViewDoc = true;
        },



        async getDocuments(docTypeId) {

            this.loadingDocuments = true;

            try {
                const response = await axios.get(`/api/user-docs`, {
                    params: { start: 0, limit: 1000, search: { "type": docTypeId } }
                });

                if (response.data && response.data.status && response.data.content) {

                    this.documentsCenter = response.data.content.data.map(doc => {

                        return {
                            isSelected: false,
                            name: doc.name,
                            type: doc.type,
                            created_at: doc.created_at,
                            file_url: doc.file_url,
                            id: doc.id

                        };
                    }).sort((a, b) => {
                        return new Date(b.created_at) - new Date(a.created_at);
                    });
                }


            } catch (error) {
                console.error("Erro ao obter documentos", error);
            } finally {
                this.loadingDocuments = false;
            }
        },

        async saveNewDocument() {
            if (!this.$refs.formNewDocument.validate()) return;

            this.dataset.loading = true;

            try {
                let formData = new FormData();

                formData.append('name', this.newDocument.name);
                formData.append('type', this.currentDocTypeId);
                formData.append('file', this.newDocument.file);

                const response = await axios.post('/api/user-docs', formData);

                await this.getDocuments(this.currentDocTypeId);
                this.closeAddModalDocument();

                this.newDocument = [];

                this.$dialog.notify.success("Documento salvo com sucesso", { position: 'top-right', timeout: 5000 });
            } catch (error) {
                this.$dialog.notify.error("Erro ao salvar o documento", { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },
    },


};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    font-family: 'Raleway';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    font-family: 'Raleway';
    font-size: 14px;
    font-style: normal;
}

.custom-modal-title {
    font-size: 20px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.file-text {
    font-size: 12px;
}
</style>
