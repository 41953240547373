const data = [
    { 
        id: 1,
        name: "Principais Documentos",
    },
    { 
        id: 2,
        name: "Relatório de Atividades",
    },
    { 
        id: 3,
        name: "Atas",
    },
    { 
        id: 4,
        name: "Ajuizamento",
    },
    { 
        id: 5,
        name: "Decisões",
    },
    { 
        id: 6,
        name: "Manifestações - Grupo 123 Milhas",
    },
    { 
        id: 7,
        name: "Manifestações - Credores",
    },
    { 
        id: 8,
        name: "Manifestações - Administração Judicial",
    },
    { 
        id: 9,
        name: "Recursos",
    },
    { 
        id: 10,
        name: "Outros",
    },
]

export default [...data]