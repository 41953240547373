<template >
  <v-container>
  <!-- <section class="limiter-content"> -->
    <!-- <div class="padding-block"></div> -->

    <!-- <v-row justify="center">
      <v-col lg="12">
        <h1 class="custom-title-page text-uppercase black--text font-weight-bold">
          Fale
          <span class="underline text-uppercase primary--text">conosco</span>
        </h1>
      </v-col>
    </v-row> -->

    <v-row>
      <v-col>
          <div class="font-weight-bold mt-10 title-page">Contato</div>
      </v-col>
    </v-row>

    <br />

    <div class="mt-4" v-if="dataset.loading">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </div>

    <div v-html="dataset.description"></div>

  
    <!-- <v-row>
      <whatsButton />
    </v-row>

    <v-row>
      <topButton />
    </v-row> -->

  <!-- </section> -->
  </v-container>
</template>

<script>
import 'moment/locale/pt-br';
//import topButton from '@/components/layout/GoToTopButton.vue';
//import whatsButton from '@/components/layout/WhatsAppButton.vue';

import axios from "axios";

export default {
  // components: {
  //   topButton,
  //   whatsButton,
  // },
  data() {
    return {
      dataset: {
        description: '',
        loading: false,
      }
     
    }
  },

  mounted: function () { },

  created() {
    this.$ga.event({
      eventCategory: 'page',
      eventAction: 'action',
      eventLabel: 'click',
      eventValue: 'contact'
    })

    this.loadText();
  },

  methods: {
   
    async loadText() {

      this.dataset.loading = true;

      try {
        const result = await axios.get(`/api/dynamic-infos/keys`, {
          params: { keys: 'contact-description' }
        })

        this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';
        
      } catch (error) {
        this.$dialog.notify.error(this.$codes(error), {
          position: 'top-right',
          timeout: 2000
        })
      }finally {
        this.dataset.loading = false
      }
    }
  }
}
</script>

<style lang="scss">

p {
  text-align: justify;
}

.formFields {
  width: 100%;
  margin-bottom: -30px;
}

.formContact {
  width: 75%;
  margin-right: -15px;
  margin-left: 15px;
}

.sendButton-contact {
  margin-right: 0px !important;
  margin-top: 1px !important;
  font-size: 18px !important;
  width: 150px;
  height: 50px !important;
  color: white !important;
  background-color: #009986 !important;
  transition: background-color 0.3s ease;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.sendButton:hover {
  background-color: #0077cc !important;
}
</style>
