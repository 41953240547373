<template>
    <!-- <section>-->
    <v-container>    
        <div class="limiter-content pa-2 pa-lg-0">
            
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-10 title-page">Modelos de Documentos</div>
                </v-col>
            </v-row>
    
            <br />

            <div class="mt-4" v-if="dataset.loading">
                <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </div>

            <div v-html="dataset.description"></div>          

            <br />

            <v-list>
                <a
                v-for="(item, index) in dataset.documents"
                :key="index"
                :href="item.file_url"
                class="pa-0 d-flex  flex-column align-start list-item-link mb-2"
                target="_blank"
                >
                <v-col class="pa-0">
                    <v-divider class="primary"></v-divider>
                    <v-list-item class="d-flex align-center">
                    <v-list-item-avatar>
                        <!-- <v-img src="@/assets/Logo-Brizola-Japur-Small.jpg"></v-img> -->
                        <v-img src="@/assets/logo-123milhas.png"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="text-uppercase font-weight-medium mediumGray--text">{{
                        item.name
                        }}</v-list-item-title>
                    </v-list-item-content>
                        <v-icon>mdi-download</v-icon>
                    </v-list-item>
                </v-col>
                </a>
                <v-divider class="primary"></v-divider>
            </v-list>

            <!-- <v-row>
                <whatsButton/>
            </v-row>
            
            <v-row>
                <topButton/>
            </v-row> -->
        </div>
    </v-container>
    <!-- </section> -->
</template>
        
<script>
import 'moment/locale/pt-br';
import axios from 'axios';

import { eventHub } from "@/main";
//import topButton from '@/components/layout/GoToTopButton.vue';
//import whatsButton from '@/components/layout/WhatsAppButton.vue';

export default {
    // components: {
    //     topButton,
    //     whatsButton,
    // },
    
    data: () => ({
        user: null,
        dialogPopup: false,
        dataset: {
            documents: [],
            description: '',
            loading: false,
        }
    }),

    mounted: function () {
    },

    created() {
        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'home'
        })
        
        this.loadText();
        this.loadDocuments();
    },

    methods: {
        openRegister() {
            eventHub.$emit("DIALOG-REGISTER", true)
        },

        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                params: { keys: 'documents-template-description' }
                });

                if (result.data.content && result.data.content.length > 0) {
                    this.dataset.description = result.data.content[0].value;
                } else {
                    this.dataset.description = ''; 
                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },

        async loadDocuments() {

            this.dataset.loading = true;

            try {
                const result = await axios.get('/api/document-models')

                this.dataset.documents = result.data.content.data
           
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { 
                    position: 'top-right', 
                    timeout: 5000 
                })
            }finally {
                this.dataset.loading = false;
            }
        }

    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: "Raleway";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}
</style>
