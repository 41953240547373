import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { pt } from 'vuetify/lib/locale'
import { en } from 'vuetify/lib/locale'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    current: 'ptbr'
  },
  // lang: {
  //   locales: {
  //     pt,
  //     en
  //   },
  //   current: 'pt'
  // },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#2b3985',
        secondary: '#0D9A87',
        mediumViolet: '#7199fb',
        gray: '#F7F7F7',
        warning: '#FFCC00',
        lightGray: '#EFEFEF',
        black: '#000000',
        mediumGray: '#959595',
        darkGray: '#9E9E9E',
        important: '#FB3A04',
        lightImportant: '#FEF2F2',
        white: '#FFFFFF',
        accent: '#AAD3C7',
        error: '#FF5252'
      }
    }
  }
})
