const data = [
  {
    id: 1,
    name: "BRL (R$)",
    currencyFormat: "R$"
  },
  {
    id: 2,
    name: "USD ($)",
    currencyFormat: "$"
  },
  {
    id: 3,
    name: "EUR (€)",
    currencyFormat: "€"
  },
]


export default [...data]
