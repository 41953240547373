
























import Vue from "vue";

//import Home from "./views/Home.vue";

export default Vue.extend({
    name: "App",

    components: {
        //Home
    },

    data: () => ({
        dialogPopup: true
    }),

    methods: {
        open: function () {
            this.$data.drawer = !this.$data.drawer;
        }
    },

    computed: {
        hide: function () {
            return this.$route.path === "/login" || this.$route.path === "/register" || /^\/requests\/.*\/view$/.test(this.$route.path);
        },

        cssProps() {
            var themeColors = {}
            Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
            })
            return themeColors
        }
    },
    created: function () {
        //$root.user = parse(localStorage.getItem('user'));
    }
});
