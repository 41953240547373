<template>
    <v-container fluid class="pa-0 pt-4">
        <div class="image">
            <!-- <video preload="true" loop autoplay muted class="video">
                <source src="@/assets/intro-video.mp4" type="video/mp4" />
            </video> -->
            <video class="video" autoplay muted controls>
                <source src="@/assets/intro-video.mp4" type="video/mp4" />
            </video>
            <!-- <v-img src="@/assets/home_logo_large.jpeg" class="fill-height image-home"></v-img> -->
        </div>

        <div class="px-12">
            <v-row>
                <v-col>
                    <div class="font-weight-bold mt-10 title-page">Início</div>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <span class="page-subtitle text-justify">Bem-vindo(a) </span>
                </v-col>
            </v-row>

            <!-- <v-row no-gutters>
                <v-col>
                    <span class="page-subtitle text-justify">Conheça a plataforma </span>
                    <br>
                    <iframe width="600" height="500" src="https://www.youtube.com/embed/cW5OmY0sP-o" frameborder="0"
                        allowfullscreen></iframe>
                </v-col>
            </v-row> -->

            <v-row>
                <v-col>
                    <p class="text-justify">
                        Este site é mantido pelos Administradores Judiciais nomeados pela 1ª Vara Empresarial de
                        Belo Horizonte/MG: Paoli, Balbino e Balbino administração Judicial, Brizola e Japur
                        Administração Judicial e Inocêncio de Paula Advogados.
                        <br />
                        <br />
                        Por meio desta plataforma, os credores das empresas 123 VIAGENS E TURISMO LTDA., ART
                        VIAGENS E TURISMO LTDA., NOVUM INVESTIMENTOS PARTICIPAÇÕES S.A., LH - LANCE HOTEIS LTDA. e
                        MM TURISMO & VIAGENS S.A. devem apresentar os pedidos de habilitação e divergência de
                        crédito (pedidos de alteração da lista de pessoas com valores a receber na Recuperação
                        Judicial). Para saber informações sobre o processo, consulte a aba “SOBRE O PROCESSO”.
                    </p>
                </v-col>
            </v-row>
            <v-row>
                <v-col align="center">
                    <span class="text-justify page-subtitle font-weight-bold">APRESENTE JÁ O SEU PEDIDO DE ALTERAÇÃO DA
                        LISTA!
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="text-justify">
                        Data final: 26/11/2024.
                        <br />
                        <br />
                        A partir da publicação do Edital do art. 52, § 1º, inc. II, da Lei 11.101/2005, está
                        <strong>ABERTO</strong> o prazo de 30 (trinta) dias para pedidos de <strong>HABILITAÇÃO DE
                            CRÉDITO</strong> (inclusão de
                        nome e valor) ou <strong>DIVERGÊNCIA DE CRÉDITO</strong> (exclusão de nome ou correção de
                        valores),
                        conforme art. 7º, §1º, da Lei 11.101/05.
                        <br />
                        <br />
                        <strong>AOS ADVOGADOS, REGISTRAMOS QUE NÃO SERÃO ACEITAS HABILITAÇÕES OU DIVERGÊNCIAS DENTRO DO
                            PROCESSO</strong>,
                        conforme decisões de ID 9912619803 e ID 9908103191 (consulte na aba "SOBRE O PROCESSO").
                    </p>
                </v-col>
            </v-row>
        </div>

        <!-- <v-dialog v-model="dialogPopup" :width="700">
            <v-img src="@/assets/images/popup-lista-credores.jpg">
                <div align="right">
                    <v-btn icon elevation="0" @click="dialogPopup = false"><v-icon>mdi-close</v-icon></v-btn>
                </div>
            </v-img>
        </v-dialog> -->

        <v-dialog v-if="recoveryToken" persistent v-model="dialogRecoverPassword" :width="600">
            <recover-password-component :token="recoveryToken"></recover-password-component>
        </v-dialog>
    </v-container>
</template>

<script>
import 'moment/locale/pt-br'

import { eventHub } from '@/main'
import axios from 'axios'

import RecoverPassword from '@/views/user/RecoverPassword.vue'

export default {
    components: {
        'recover-password-component': RecoverPassword
    },

    data: () => ({
        user: null,
        dialogPopup: false,
        recoveryToken: null,
        dialogRecoverPassword: false
    }),

    mounted: function () { },

    created() {
        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'home'
        })

        eventHub.$on('DIALOG-CHANGE-PASSWORD', value => {
            if (!value) this.$router.replace('/home')

            this.dialogRecoverPassword = value
        })

        eventHub.$on('DIALOG-REGISTER', value => {
            if (!value) this.$router.replace('/home')
        })

        if (this.$route.query.token) {
            this.verifyAccount(this.$route.query.token)
        }

        if (this.$route.query.recoveryToken) {
            this.verifyRecoverPasswordToken(this.$route.query.recoveryToken)
        }
    },

    methods: {
        openRegister() {
            eventHub.$emit('DIALOG-REGISTER', true)
        },

        async verifyAccount(token) {
            if (!token) return

            this.loading = true

            try {
                const normalizedToken = decodeURIComponent(token.toString().replaceAll(' ', '+'))

                const result = await axios.post('/api/users/verify-account', { token: normalizedToken })

                if (!result.data.status) throw new Error()

                await this.$dialog.confirm({
                    text: 'Conta verificada com sucesso, realize o login para acessar a "Área do Credor"!',
                    title: 'Sucesso',
                    actions: {
                        true: 'OK'
                    }
                })

                this.$router.replace('/home')
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 })
            } finally {
                this.loading = false
            }
        },

        async verifyRecoverPasswordToken(token) {
            if (!token) return

            this.recoveryToken = decodeURIComponent(token.toString().replaceAll(' ', '+'))

            this.dialogRecoverPassword = true
        }
    }
}
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.image {
    width: 100vw;
    overflow: hidden;
    z-index: 999;
}

.video-container {
    max-width: 100%;
    max-height: 100%;
}

.video {
    width: 100%;
    height: auto;
}
</style>
