<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Lista de Credores</div>
            </v-col>
        </v-row>

        <br>

        <div v-html="dataset.description"></div>

        <v-row>
            <v-col sm="12">
                <v-text-field id="name" label="Buscar pelo nome do credor" hint="No mínimo 4 dígitos para fazer a busca"
                    persistent-hint v-model="filter" outlined @keyup.enter="handleEnterPress"></v-text-field>
                <v-btn class="mt-2" id="btnFilter" type="submit" color="primary" dark elevation="2" large
                    :disabled="isFilterDisabled" @click="!isFilterDisabled ? reload() : null">Filtrar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card class="table-card">
                    <v-card-title class="table-card-title">Lista de credores</v-card-title>
                    <v-card-text>
                        <v-data-table :headers="dataList.headers" :items="dataList.data"
                            :server-items-length="dataList.count" @update:items-per-page="pageSizeChanged"
                            @update:page="pageChanged" :disable-sort="true" :loading="dataList.loading"
                            no-data-text="Nenhum credor foi encontrado" loading-text="Carregando dados..."
                            class="app-table hover-table" :options.sync="dataList.options"
                            :footer-props="dataList.footerOptions">

                            <!-- <template v-slot:[`item.amount`]="{ item }">
                                <span>{{ item.amount | currency }}</span>
                            </template> -->

                            <template v-slot:[`item.amount`]="{ item }">
                                <span>{{ formatValue(item.amount, item.currency) }}</span>
                            </template>

                            <template v-slot:[`item.type`]="{ item }">
                                <v-chip label text-color="primary">
                                    {{ getClassName(item.type) }}
                                </v-chip>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn dense elevation="0" @click="creditorDetails(item)">
                                    <span class="caption font-weight-bold">Ver</span>
                                    <v-icon small>mdi-arrow-right</v-icon>
                                </v-btn>
                            </template>

                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div class="collapse-title">Lista de credores em PDF</div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col>
                                    <v-card elevation="0" class="mb-2">
                                        <v-card-text><a style="text-decoration: none;" target="_blank"
                                                href="https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/ME_EPP.pdf">
                                                LISTA DE CREDORES - GRUPO 123 MILHAS - CLASSE IV - ME
                                                EPP</a></v-card-text>
                                    </v-card>
                                    <v-card elevation="0" class="mb-2">
                                        <v-card-text><a style="text-decoration: none;" target="_blank"
                                                href="https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Trabalhista.pdf">
                                                LISTA DE CREDORES - GRUPO 123 MILHAS - CLASSE I -
                                                TRABALHISTAS</a></v-card-text>
                                    </v-card>
                                    <v-card class="mb-2" elevation="0" v-for="(item, index) in fileList" :key="index">
                                        <v-card-text><a style="text-decoration: none;" target="_blank"
                                                :href="item.link">LISTA
                                                DE CREDORES - GRUPO 123 MILHAS - CLASSE III - QUIROGRAFÁFIOS - LETRA {{
                                                item.value }}</a></v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>

        <!-- MODAL -->
        <v-dialog v-model="dialog" width="800px">
            <v-card class='pa-3'>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase primary--text font-weight-bold">
                                VERIFICAÇÃO DE CRÉDITOS - Resumo do credor

                            </h1>
                            <br>
                            <span class="resume-modal-case-name">{{ dataList.modalData.name }}</span>
                            <br>

                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col>
                            <v-row>
                                <v-col class="bordered-item mt-5">

                                    <p>
                                        <span class="section-info-title">EDITAL ART. 52, § 1º, II, DA LEI
                                            11.101/2005</span>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small :color="'primary'" v-bind="attrs" v-on="on"
                                                    class="ml-5">mdi-help-circle</v-icon>
                                            </template>
                                            <span>Incluído na lista de credores através da relação inicial juntada pelo
                                                Grupo
                                                123 Milhas</span>
                                        </v-tooltip>
                                        <br>
                                        <span>Valor: <strong>{{ dataset.data && dataset.data.value1 ? formatValue(dataset.data.value1, dataset.data.currency) : '0,00' }}</strong></span>
                                        <br>
                                        <span>Justificativa: <strong>{{ dataset.data && dataset.data.value1_small_reason
                                                ?
                                                dataset.data.value1_small_reason : 'N/A' }}</strong></span>
                                    </p>

                                    <p>
                                        <span class="section-info-title">EDITAL ART. 7º, § 2º, DA LEI 11.101/2005</span>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small :color="'primary'" v-bind="attrs" v-on="on"
                                                    class="ml-5">mdi-help-circle</v-icon>
                                            </template>
                                            <span>Incluído na lista de credores através da análise extrajudicial de
                                                créditos
                                                pelos Administradores Judiciais</span>
                                        </v-tooltip>
                                        <br>
                                        <span>Valor: <strong> {{ dataset.data && dataset.data.value2 ? formatValue(dataset.data.value2, dataset.data.currency) : '0,00'}}</strong></span>
                                        <br>
                                        <span>Justificativa: <strong> {{ dataset.data &&
                                            dataset.data.value2_small_reason ?
                                                dataset.data.value2_small_reason : 'N/A' }} </strong></span>
                                    </p>

                                    <p>
                                        <span class="section-info-title">ANÁLISE ARTS. 8º e 10º DA LEI
                                            11.101/2005</span>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small :color="'primary'" v-bind="attrs" v-on="on"
                                                    class="ml-5">mdi-help-circle</v-icon>
                                            </template>
                                            <span>Incluído na lista de credores através do ajuizamento de incidente
                                                judicial
                                                (habilitação retardatária ou impugnação de crédito)</span>
                                        </v-tooltip>
                                        <br>
                                        <span>Valor: <strong> {{ dataset.data && dataset.data.value3 ? formatValue(dataset.data.value3, dataset.data.currency) : '0,00' }}</strong></span>
                                        <br>
                                        <span>Justificativa: <strong> {{ dataset.data &&
                                            dataset.data.value3_small_reason ?
                                                dataset.data.value3_small_reason : 'N/A' }} </strong></span>
                                    </p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary darken-1" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import { eventHub } from "@/main"

import axios from "axios";

import _ from 'lodash';

export default {
    data: () => ({
        user: null,

        filter: null,
        dataset: {
            description: '',
            data: [],
        },
        dialog: false,
        dataList: {
            filter: {
                name: "",
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "CPF/CNPJ", value: "registration" },
                { text: "Classe", value: "type" },
                { text: "Valor", value: "amount" },
                { text: 'Detalhes', value: 'actions', sortable: false }
            ],
            modalData: {},
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 5,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [5, 10, 20, 50],
                pageText: '{0}-{1} de {2}',
            }
        },
        fileList: [
            { value: "A", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-A.pdf" },
            { value: "B", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-B.pdf" },
            { value: "C", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-C.pdf" },
            { value: "D", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-D.pdf" },
            { value: "E", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-E.pdf" },
            { value: "F", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-F.pdf" },
            { value: "G", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-G.pdf" },
            { value: "H", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-H.pdf" },
            { value: "I", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-I.pdf" },
            { value: "J", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-J.pdf" },
            { value: "K", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-K.pdf" },
            { value: "L", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-L.pdf" },
            { value: "M", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-M.pdf" },
            { value: "N", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-N.pdf" },
            { value: "O", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-O.pdf" },
            { value: "P", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-P.pdf" },
            { value: "Q", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-Q.pdf" },
            { value: "R", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-R.pdf" },
            { value: "S", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-S.pdf" },
            { value: "T", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-T.pdf" },
            { value: "U", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-U.pdf" },
            { value: "V", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-V.pdf" },
            { value: "W", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-W.pdf" },
            { value: "X", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-X.pdf" },
            { value: "Y", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-Y.pdf" },
            { value: "Z", link: "https://rj123milhas-docs-dev.s3.sa-east-1.amazonaws.com/list-creditors/Letra-Z.pdf" },
        ],
    }),

    mounted: function () {
    },

    computed: {
        isFilterDisabled() {
            return !this.filter || this.filter.length < 4;
        }
    },

    created() {
        this.loadText();

        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'list'
        })
    },

    methods: {

        openRegister() {
            eventHub.$emit("DIALOG-REGISTER", true)
        },

        handleEnterPress() {
            if (!this.isFilterDisabled) {
                this.reload();
            }
        },

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.size = size;
            this.load();
        },

        reload() {
            this.dataList.options.page = 1;
            this.dataList.options.size = 10;
            this.load();
        },

        getClassName(classId) {

            switch (classId) {
                case 1:
                    return "Trabalhista";
                case 2:
                    return "Garantia Real";
                case 3:
                    return "Quirografário";
                case 4:
                    return "ME/EPP";
                case 5:
                    return "Extraconcursais";
                case 6:
                    return "Ilíquidos";
                case 7:
                    return "Fiscal";
                case 8:
                    return "Não Sujeito";
                case 9:
                    return "Subordinados";
                default:
                    return "N/A";
            }
        },

        stripHtmlTags(value) {
            if (!value) return '';
            const tempDiv = document.createElement("div");
            tempDiv.innerHTML = value;
            return tempDiv.textContent || tempDiv.innerText || "";
        },

        formatValue(value, currency) {
            if (value === null || value === undefined) {
                return 'N/A';
            }

            let options = {
                style: 'currency',
                currency: 'BRL' // Default
            };

            switch (currency) {
                case 1:
                    options.currency = 'BRL';
                    break;
                case 2:
                    options.currency = 'USD';
                    break;
                case 3:
                    options.currency = 'EUR';
                    break;
                default:
                    options.currency = 'BRL';
            }

            return new Intl.NumberFormat('pt-BR', options).format(value);
        },


        // formatValue(value) {

        //     if (value === null || value === undefined) {
        //         return 'N/A';
        //     }

        //     return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
        // },


        async creditorDetails(item) {

            this.dataList.modalData = item

            try {
                const result = await axios.get('/api/creditor-list/detail', { params: { id: item.id } })

                if (result.data && result.data.content && result.data.content.data && result.data.content.data.length > 0) {
                    this.dataset.data = result.data.content.data[0];

                    if (this.dataset.data.value1_small_reason) {
                        this.dataset.data.value1_small_reason = this.stripHtmlTags(this.dataset.data.value1_small_reason);
                    }

                    if (this.dataset.data.value2_small_reason) {
                        this.dataset.data.value2_small_reason = this.stripHtmlTags(this.dataset.data.value2_small_reason);
                    }

                    if (this.dataset.data.value3_small_reason) {
                        this.dataset.data.value3_small_reason = this.stripHtmlTags(this.dataset.data.value3_small_reason);
                    }

                } else {
                    this.dataset.data = {};
                }

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }

            this.dialog = true;
        },


        async loadText() {
            try {
                const result = await axios.get(`/api/dynamic-infos/keys`, {
                    params: { keys: 'creditor-list-description' }
                })

                this.dataset.description = result.data.content && result.data.content.length > 0 ? result.data.content[0].value : '';

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },


        async load() {

            this.dataList.loading = true;

            var limit = this.dataList.options.size;

            try {

                const result = await axios.get('/api/creditor-list', { params: { page: this.dataList.options.page, limit: limit, search: this.filter } });

                this.dataList.data = result.data.content.data;
                this.dataList.count = result.data.content.paging.total;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },


    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.collapse-title {
    text-transform: none;
    color: var(--v-primary-base);
    font-size: 24px;
    font-weight: 700;
}

#btnFilter.v-btn--disabled {
    background-color: rgb(197, 192, 192) !important;
}

.bordered-item {
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 4px;
}
</style>
