<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="font-weight-bold mt-10 title-page">Sobre o Processo</div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <p class="text-justify">
                    <strong>Nº do processo:</strong> 5194147-26.2023.8.13.0024
                    <br>
                    <br>
                    <strong>Empresas em Recuperação (GRUPO 123 MILHAS):</strong> 123 VIAGENS E TURISMO LTDA (CNPJ:
                    26.669.170/0001-57), ART VIAGENS E TURISMO LTDA (CNPJ: 11.442.110/0001-20) e NOVUM INVESTIMENTOS
                    PARTICIPAÇÕES S.A (CNPJ: 26.941.940/0001-79)
                    <br>
                    <br>
                    <strong>Link para consulta pública do processo:</strong> <a target="_blank"
                        href="https://pje.tjmg.jus.br/pje/">https://pje.tjmg.jus.br/pje/</a>
                </p>
                <p class="text-justify">
                    <strong> Status atual do processo:</strong> Em 29/08/2023, o Grupo 123 Milhas ajuizou pedido de
                    Recuperação Judicial. Em 31/08/2023, o processamento foi deferido pela 1ª Vara Empresarial de Belo
                    Horizonte/MG, tendo sido nomeados para atuar como Administradores Judiciais, em conjunto, Paoli Balbino
                    & Barros Advogados e Brizola e Japur Administração Judicial (responsáveis pelo presente canal de
                    comunicação).
                    <br>
                    <br>
                    O Edital do art. 52 §1º inciso I da Lei 11.101/2005 informando o deferimento do processamento da
                    Recuperação Judicial foi publicado em 01/09/2023.
                    <br>
                    <br>
                    Em breve será publicado pelo Juízo o Edital do art. 52, § 1º, incisos II e III da Lei 11.101/2005,
                    contendo relação nominal de credores apresentada pelas Devedoras, isto é, a lista oficial das pessoas
                    com valores a receber pelo Grupo 123 Milhas.
                    <br>
                    <br>
                    Tendo em vista que a Administração Judicial já recebeu as informações necessárias para dar início à fase
                    administrativa de verificação de créditos, já está aberto o cadastro nesse site para pedidos de
                    HABILITAÇÃO DE CRÉDITO (inclusão do nome) ou DIVERGÊNCIA DE CRÉDITO (exclusão de nome ou correção de
                    valores).
                    <br>
                    <br>
                    É a publicação do Edital do art. 52, § 1º, incisos II e III da Lei 11.101/2005 que determina o prazo de
                    15 (quinze) dias, isto é, fixa o dia final para envio dos pedidos e documentos.
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <span class="page-subtitle">Principais Documentos</span>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card v-for="(item, index) in dataset.docs" :key="index" elevation="2" class="mt-4 mb-4"
                    style="cursor: pointer;" :href="item.file_url" target="_blank">
                    <v-card-text>
                        <v-row align="center">
                            <div class="py-2 ml-4">
                                <v-icon size="30" class="mr-4" color="primary">mdi-file-document-outline</v-icon>
                                <span class="document-card-title">{{ item.name }}</span>
                            </div>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import axios from "axios"

export default {
    data: () => ({
        user: null,
        dataset: {
            docs: []
        },
        documents: [
            { name: "Petição inicial", link: "https://assets.rj123milhas.com.br/juridico/1_peticao_inicial.pdf" },
            { name: "Decisão de deferimento do processamento", link: "https://assets.rj123milhas.com.br/juridico/2_decisao_deferimento_processamento.pdf" },
        ]
    }),

    mounted: function () {
    },

    created() {
        this.load()

        this.$ga.event({
            eventCategory: 'page',
            eventAction: 'action',
            eventLabel: 'click',
            eventValue: 'about'
        })
    },

    methods: {

        async load() {

            try {
                const result = await axios.get(`/api/documents`, { params: { limit: 100000 } });

                const data = result.data.content.data;

                this.dataset.docs = data.sort((a, b) => a.order - b.order);

            } catch (error) {
                this.$dialog.notify.error('Ocorreu um erro ao carregar os documentos do processo, tente novamente!', {
                    position: 'top-right',
                    timeout: 2000
                })
            }
        },
    }
};
</script>

<style lang="scss">
.title-page {
    color: var(--v-primary-base);
    font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.document-card-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 18px;
    line-height: 0px;
}
</style>
