import Vue from 'vue';
import moment from 'moment'

Vue.filter('defaultDate', (value, format, breakLine = false) => {
    if (value) {
        moment.locale('pt');

        if (format === 'fromNow') {
            return moment(value).fromNow();
        } else {
            if (!format) {
                format = 'DD/MM/YYYY';
            }

            if (breakLine) {
                format = format.replace(' ', '[<br />]');
            }

            return moment(value).format(format);
        }
    }

    return value;
});