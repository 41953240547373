const data = [
    { 
        id: 1,
        name: "Venda de milhas",
    },
    { 
        id: 2,
        name: "Compra de passagem",
    },
    { 
        id: 3,
        name: "Reserva de hotel",
    },
    { 
        id: 4,
        name: "Nota fiscal",
    },
    { 
        id: 5,
        name: "Duplicatas",
    },
    { 
        id: 6,
        name: "Reclamatória trabalhista",
    },
    { 
        id: 7,
        name: "Contrato de trabalho",
    },
    { 
        id: 8,
        name: "Outros negócios",
    },
  ]
  
  
  export default [...data]
  